import { Grid, ButtonGroup, Button } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { BreadCrumbs, EditButton, formatters } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
// import ContractEditModal from './contractAddEditModal';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { GetHireContractExcel } from '../../../../Core/Services/InternalUser/excelDownload';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Contracts', path: '/', active: true }
];

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 80,
        addFileUploaderIcon: true
    },
    { key: 'customer', name: 'Customer', width: '15%' },
    { key: 'ref', name: 'Ref', width: '12%' },
    { key: 'type', name: 'Contract Type', width: '15%' },
    // { key: 'title', name: 'Title', width: '10%' },
    { key: 'startDate', name: 'Start Date', formatter: formatters.Date_DDMMYYYY, width: '10%' },
    { key: 'endDate', name: 'End Date', formatter: formatters.Date_DDMMYYYY, width: '10%' },
    // { key: 'description', name: 'Description', width: '15%' },
    { key: 'contractTerm', name: 'Contract Term', width: '13%' },
    { key: 'winterTerm', name: 'Winter Term', width: '11%' },
    { key: 'winterStartDate', name: 'Winter Start Date', formatter: formatters.Date_DDMMYYYY, width: '15%' },
    { key: 'sparesRequired', name: 'Spares Required', width: '15%' },
    { key: 'framework', name: 'Framework', width: '15%' },
    { key: 'vehicles', name: 'Vehicles', width: '8%' },
    { key: 'status', name: 'Status', width: '10%' }
    // { key: 'depot', name: 'Depot', width: '15%' }
];

const ContractList = () => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const history = useHistory();
    const [state, setState] = useState({ contractStatusID: 'A' });

    const handleEditClick = (val) => {
        history.push({
            pathname: `/contractList/editcontractList/${val?.contractID || ''}`
        });
    };
    const handleChange = (statusID) => {
        setState((st) => ({ ...st, contractStatusID: statusID }));
    };
    const editActionBtn = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleEditClick(row)} toolTipTitle="Edit Address" />
            </div>
        );
    };

    const handleModal = (res) => {
        setState((st) => ({ ...st, showModal: true, selectedRecord: res }));
        // res && pullDepotsAndUpdateState();
    };

    const handleClose = (res) => {
        setState((st) => ({ ...st, showModal: false }));
        // res && pullDepotsAndUpdateState();
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = editActionBtn;
        }
        return tempCols;
    }, []);

    let getUrl = `Contracts/Contract_List?StatusID=${state.contractStatusID}`;
    return (
        <div className="screen">
            <Grid container>
                <Grid container justify="space-between">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                    </Grid>
                    <Grid component="label" item alignItems="center">
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button
                                style={{ fontSize: 12 }}
                                variant={state.contractStatusID === 'A' ? 'contained' : 'outlined'}
                                onClick={() => handleChange('A')}
                            >
                                Active
                            </Button>
                            <Button
                                style={{ fontSize: 12 }}
                                variant={state.contractStatusID === 'C' ? 'contained' : 'outlined'}
                                onClick={() => handleChange('C')}
                            >
                                Completed
                            </Button>
                            <Button
                                style={{ fontSize: 12 }}
                                variant={state.contractStatusID === 'X' ? 'contained' : 'outlined'}
                                onClick={() => handleChange('X')}
                            >
                                Cancelled
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={baseColumns}
                    getUrl={getUrl}
                    btnTitle={checkhaveAccess?.CanAddContracts ? 'Add Contract' : ''}
                    onBtnClick={handleEditClick}
                    isReload={state.isReload}
                    idFieldName={`${checkhaveAccess?.CanUploadContractDocuments ? 'contractID' : null}`}
                    fileUploaderConfigName={`${checkhaveAccess?.CanUploadContractDocuments ? 'ContractScreen' : null}`}
                    // mapBtnToolTipTitle={mapBtnTitle}
                    // mapBtnIcon={RoomIcon}
                    // mapBtnLabel={mapBtnTitle}
                    // onMapBtnClick={handleShowLocation}
                />
            </Grid>
            {/* {state.showModal ? (
                <DialogComp title={'Contract'} onClose={() => handleClose(false)} maxWidth="lg" fullWidth>
                    <ContractEditModal onClose={handleClose} selectedRecord={state.selectedRecord || {}} />
                </DialogComp>
            ) : null} */}
        </div>
    );
};
export default ContractList;
