import React from 'react';
import { AddButton, EditButton, BreadCrumbs, formatters, SecondaryButton, SimpleRating } from '../Controls';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import DialogComp from '../Dialog/DialogComp';
import { Alert } from '@material-ui/lab';

export default function ConfirmationModal(props) {
    return (
        // <DialogComp title={props.title} onClose={props.handleCancel} maxWidth="lg">
        <Grid container spacing={2}>
            {props.isAlert ? (
                <Grid item xs={12}>
                    <Alert severity={props.alertType ? props.alertType : 'info'}>{props.message}</Alert>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Typography component="legend" style={{ textAlign: props.textAlign ? props.textAlign : 'left' }}>
                        {props.message}
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={props.handleCancel ? 6 : 12}>
                        <SecondaryButton isBtnLoader={props?.isBtnLoader || false} onClick={props.handleSubmit} fullWidth className="submit_btn">
                            Confirm
                        </SecondaryButton>
                    </Grid>
                    {props.handleCancel && (
                        <Grid item xs={6}>
                            <SecondaryButton onClick={props.handleCancel} fullWidth className="submit_btn">
                                Cancel
                            </SecondaryButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
        // </DialogComp>
    );
}
