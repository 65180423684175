import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { InputText } from '..';
import { useLoginInfo } from '../../Hooks/useLoginInfo';

const SelectBox = (props) => {
    const { List, inputLabel, forceEditable, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    forceEditable && (isReadOnlyUser = false);
    let dropdownValueName = '';
    if (isReadOnlyUser && rest.value) {
        dropdownValueName = List.find((l) => l.id === rest.value)?.name;
    }
    let insertEmpty = rest.insertEmpty;
    if (insertEmpty === undefined) {
        insertEmpty = true;
    }
    return isReadOnlyUser ? (
        <InputText {...rest} value={dropdownValueName} />
    ) : (
        <TextField select size="small" variant="outlined" {...rest} fullWidth>
            {insertEmpty && (
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
            )}
            {List.length > 0 &&
                List.map((option) => (
                    <MenuItem key={option.id} value={option.id || ''}>
                        {option.name}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default SelectBox;
