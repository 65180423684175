import {
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, DateTimePicker, MandatoryField, SecondaryButton, SelectBox, SimpleField, SingleSelect, formatters } from '../../../../Core/Controls';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { getUserDropdown } from '../../../../Core/Services/Admin/userService';
import { getVehicleScreenDropdowns } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { postHireFleetContract } from '../../../../Core/Services/InternalUser/contractService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { Alert } from '@material-ui/lab';
import { getContractsDropDown } from '../../../../Core/Services/InternalUser/Vehicle/contractVechicleService';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { getJobEnggById } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const YesNoRadio = (props) => {
    return (
        <Grid container>
            <Grid container item spacing={1} style={{ marginLeft: '2px' }}>
                <RadioGroup row aria-label={props.name} name={props.name} value={props.value} onChange={props.onChange}>
                    <FormControlLabel checked={props.value === true} value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={props.value === false} value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
        </Grid>
    );
};

const HireFleetLink = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        errors: {},
        contractID: props.vehicleContractID,
        contractVehicleID: props.vehicleID,
        showLoader: props.vehicleContractID ? true : false,
        contractStartDate: '',
        contractEndDate: '',
        contractExtendedDate: '',
        contractTerm: '',
        contractMileage: '',
        contractExcessMileage: '',
        contractCollectionChargeDate: '',
        contractWinterTerms: '',
        contractWinterStartDate: '',
        contractWinterEndDate: '',
        contractWeeklyRental: '',
        contractInvoiceFrequency: '',
        contractInvoiceAllYear: false,
        contractDeliveryArranged: false,
        contractDeliveryDate: '',
        contractDeliveryCharge: '',
        contractOnsiteDate: '',
        contractCollectionArranged: false,
        contractCollectionDate: '',
        contractCollectionCharge: '',
        contractOffhireOverride: false,
        contractReturnDate: '',
        contractPloughRequired: false,
        contractPloughCharge: '',
        contractCamera: false,
        contractCameraCharge: '',
        contractTracker: false,
        contractTrackerCharge: '',
        contractNavigation: '',
        contractNavigationCharge: '',
        contractOnboardWeighing: false,
        contractOnboardWeighingCharge: '',
        contractTempProbe: false,
        contractTempProberCharge: '',
        contractMOT: false,
        contractMOTCharge: '',
        contractMidSeasonCalibration: false,
        contractAnnualPriceIncrease: false,
        contractMidSeasonCalibrationCharge: '',
        contract12WeeklyBrakeRollerTests: false,
        contract12WeeklyBrakeRollerTestsCharge: '',
        contractBespoke: false,
        contractBespokeCharge: '',
        contractPurchaseOrder: '',
        contractFramework: '',
        contractNotes: '',
        contractDepotID: null,
        selectedcontract: ''
    });
    const [tempOptions, setTempOptions] = useState({
        contractID: null,
        contractDepotID: null,
        contractServiceAreaID: null,
        contractEngineerUserID: null,
        contractSummerServiceAreaID: null
    });
    const [dropdown, setDropdown] = useState({
        contractDurationTypes: [],
        contractTypes: [],
        agreementTypes: [],
        maintenanceProviders: [],
        contractStatus: [],
        serviceAgentsList: [],
        depotsList: [],
        technicianList: [],
        contractsList: []
    });
    useEffect(() => {
        const fetchDropDownData = async () => {
            const res = await getContractsDropDown();
            if (res.success) {
                const { contractTypes, maintenanceProviders, contractStatus, serviceAgents, depots, technician, contracts } = res.data;
                setDropdown((st) => ({
                    ...st,
                    contractsList: contracts.filter((m) => m.contractTypeID == 1) || [],
                    contractTypes: contractTypes.map(({ contractTypeID, contractTypeDescription }) => ({
                        id: contractTypeID,
                        name: contractTypeDescription
                    })),

                    maintenanceProviders: maintenanceProviders.map(({ maintenanceProviderID, maintenanceProviderName }) => ({
                        id: maintenanceProviderID,
                        name: maintenanceProviderName
                    })),
                    contractStatus: contractStatus.map(({ contractStatusID, contractStatusDescription }) => ({
                        id: contractStatusID,
                        name: contractStatusDescription
                    })),
                    serviceAgentsList: serviceAgents.map(({ id, name }) => ({
                        id,
                        name
                    })),
                    depotsList: depots.map(({ id, name }) => ({
                        id,
                        name
                    }))
                    // technicianList: technician.map(({ id, name }) => ({
                    //     id,
                    //     name
                    // }))
                }));
            }
        };

        fetchDropDownData();
    }, []);

    useEffect(async () => {
        if (tempOptions.contractServiceAreaID?.id) {
            let res = await getJobEnggById(tempOptions.contractServiceAreaID?.id);
            if (res.success) {
                setDropdown((d) => ({
                    ...d,
                    technicianList: res.data.list.map(({ id, name }) => ({
                        id,
                        name
                    }))
                }));
                if (tempOptions.contractID?.id) {
                    const selectedcontract = dropdown.contractsList.find((m) => m.id == +tempOptions.contractID?.id) || null;
                    setTempOptions((st) => ({
                        ...st,
                        contractEngineerUserID: res.data.list.find(({ id }) => id === selectedcontract.contractEngineerUserID) || null
                    }));
                }
            }
        }
    }, [tempOptions.contractServiceAreaID?.id]);

    useEffect(() => {
        if (props.contractID && dropdown.contractsList.length > 0) {
            const selectedcontract = dropdown.contractsList.find((m) => m.id == +props.contractID) || null;

            setTempOptions((st) => ({
                ...st,
                contractID: selectedcontract,
                contractServiceAreaID: dropdown.serviceAgentsList.find(({ id }) => id === selectedcontract?.contractServiceAreaID),
                contractSummerServiceAreaID: dropdown.serviceAgentsList.find(({ id }) => id === selectedcontract?.contractSummerServiceAreaID),
                contractDepotID: dropdown.depotsList.find(({ id }) => id === selectedcontract?.contractDepotID),
                contractEngineerUserID: dropdown.technicianList.find(({ id }) => id === selectedcontract?.contractEngineerUserID)
            }));

            setState((st) => ({
                ...st,
                contractStartDate: selectedcontract?.contractStartDate,
                contractEndDate: selectedcontract?.contractEndDate,
                contractWinterStartDate: selectedcontract?.contractWinterStartDate,
                contractNotes: selectedcontract?.contractDescription,
                contractExtendedDate: selectedcontract?.contractExtendedDate,
                contractFramework: selectedcontract?.contractFramework,
                contractWinterTerms: selectedcontract?.contractWinterTerm,
                contractInvoiceFrequency: selectedcontract?.contractInvoiceFrequency,
                contractPurchaseOrder: selectedcontract?.contractPurchaseOrder,
                spreaderTypeName: selectedcontract?.spreaderTypeName
            }));
        }
    }, [props.contractID, dropdown.contractsList]);

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const singleSelectChange = (nm) => (ev, val) => {
        if (nm === 'contractID' && val) {
            setTempOptions((st) => {
                let newSt = { ...st };
                newSt[nm] = val;
                if (val.contractServiceAreaID) newSt.contractServiceAreaID = dropdown.serviceAgentsList.find(({ id }) => id === val.contractServiceAreaID);
                if (val.contractSummerServiceAreaID)
                    newSt.contractSummerServiceAreaID = dropdown.serviceAgentsList.find(({ id }) => id === val.contractSummerServiceAreaID);
                if (val.contractDepotID) newSt.contractDepotID = dropdown.depotsList.find(({ id }) => id === val.contractDepotID);
                if (val.contractEngineerUserID) newSt.contractEngineerUserID = dropdown.technicianList.find(({ id }) => id === val.contractEngineerUserID);

                return newSt;
            });

            setState((st) => ({
                ...st,
                contractStartDate: val.contractStartDate,
                contractEndDate: val.contractEndDate,
                contractWinterStartDate: val.contractWinterStartDate,
                contractNotes: val.contractDescription,
                contractExtendedDate: val.contractExtendedDate,
                contractFramework: val.contractFramework,
                contractWinterTerms: val.contractWinterTerm,
                contractInvoiceFrequency: val.contractInvoiceFrequency,
                contractPurchaseOrder: val.contractPurchaseOrder,
                spreaderTypeName: val.spreaderTypeName
            }));
        } else {
            setTempOptions((st) => ({
                ...st,
                [nm]: val
            }));
        }
    };

    const validations = () => {
        let formIsValid = true;
        let {
            contractStartDate,
            contractEndDate,
            contractWinterTerms,
            contractWinterStartDate,
            contractPurchaseOrder,
            contractInvoiceFrequency,
            contractDeliveryCharge,
            contractCollectionCharge,
            contractWeeklyRental
        } = state;
        let errors = {};
        if (!contractStartDate) {
            formIsValid = false;
            errors.contractStartDate = 'This field is required';
        }
        // if (!contractEndDate) {
        //     formIsValid = false;
        //     errors.contractEndDate = 'This field is required';
        // }
        if (!contractWinterTerms) {
            formIsValid = false;
            errors.contractWinterTerms = 'This field is required';
        }
        if (!contractWinterStartDate) {
            formIsValid = false;
            errors.contractWinterStartDate = 'This field is required';
        }
        if (!contractPurchaseOrder) {
            formIsValid = false;
            errors.contractPurchaseOrder = 'This field is required';
        }
        if (!contractInvoiceFrequency) {
            formIsValid = false;
            errors.contractInvoiceFrequency = 'This field is required';
        }
        if (!tempOptions.contractSummerServiceAreaID?.id) {
            formIsValid = false;
            errors.contractSummerServiceAreaID = 'This field is required';
        }
        if (!tempOptions.contractServiceAreaID?.id) {
            formIsValid = false;
            errors.contractServiceAreaID = 'This field is required';
        }
        if (!tempOptions.contractDepotID?.id) {
            formIsValid = false;
            errors.contractDepotID = 'This field is required';
        }

        if (!contractDeliveryCharge) {
            formIsValid = false;
            errors.contractDeliveryCharge = 'This field is required';
        }
        if (!contractCollectionCharge) {
            formIsValid = false;
            errors.contractCollectionCharge = 'This field is required';
        }
        if (!contractWeeklyRental) {
            formIsValid = false;
            errors.contractWeeklyRental = 'This field is required';
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            setBtnLoader(true);
            let data = {
                contractTypeID: 1,
                contractID: tempOptions.contractID?.id,
                contractStartDate: state.contractStartDate,
                contractEndDate: state.contractEndDate,
                contractWinterTerms: state.contractWinterTerms,
                contractWinterStartDate: state.contractWinterStartDate,
                contractWinterEndDate: state.contractWinterEndDate,
                contractExtendedDate: state.contractExtendedDate,
                contractPurchaseOrder: state.contractPurchaseOrder,
                contractInvoiceFrequency: state.contractInvoiceFrequency,
                contractInvoiceAllYear: state.contractInvoiceAllYear,
                contractFramework: state.contractFramework,
                contractAnnualPriceIncrease: state.contractAnnualPriceIncrease,
                contractIncreaseAmount: state.contractIncreaseAmount,
                contractIncreaseDate: state.contractIncreaseDate,
                contractDepotID: tempOptions.contractDepotID?.id,
                contractServiceAreaID: tempOptions.contractServiceAreaID?.id,
                contractEngineerUserID: tempOptions.contractEngineerUserID?.id,
                contractSummerServiceAreaID: tempOptions.contractSummerServiceAreaID?.id,
                contractDeliveryCharge: state.contractDeliveryCharge,
                // contractDeliveryArranged: state.contractDeliveryArranged,
                // contractDeliveryDate: state.contractDeliveryDate,
                // contractOnsiteDate: state.contractOnsiteDate,
                // contractOffhireOverride: state.contractOffhireOverride,
                contractCollectionCharge: state.contractCollectionCharge,
                // contractCollectionArranged: state.contractCollectionArranged,
                // contractCollectionDate: state.contractCollectionDate,
                // contractReturnDate: state.contractReturnDate,
                // contractCollectionChargeDate: state.contractCollectionChargeDate,
                contractWeeklyRental: state.contractWeeklyRental,
                contractMileage: state.contractMileage,
                contractExcessMileage: state.contractExcessMileage,
                contractNotes: state.contractNotes,
                contractPloughRequired: state.contractPloughRequired,
                contractPloughCharge: state.contractPloughCharge,
                contractPloughDescription: state.contractPloughDescription,
                contractCamera: state.contractCamera,
                contractCameraCharge: state.contractCameraCharge,
                contractCameraDescription: state.contractCameraDescription,
                contractTracker: state.contractTracker,
                contractTrackerCharge: state.contractTrackerCharge,
                contractTrackerDescription: state.contractTrackerDescription,
                contractNavigation: state.contractNavigation,
                contractNavigationCharge: state.contractNavigationCharge,
                contractNavigationDescription: state.contractNavigationDescription,
                contractOnboardWeighing: state.contractOnboardWeighing,
                contractOnboardWeighingCharge: state.contractOnboardWeighingCharge,
                contractOnboardWeighingDescription: state.contractOnboardWeighingDescription,
                contractTempProbe: state.contractTempProbe,
                contractTempProberCharge: state.contractTempProberCharge,
                contractTempProberDescription: state.contractTempProberDescription,
                contractMOT: state.contractMOT,
                contractMOTCharge: state.contractMOTCharge,
                contractMOTDescription: state.contractMOTDescription,
                contractMidSeasonCalibration: state.contractMidSeasonCalibration,
                contractMidSeasonCalibrationCharge: state.contractMidSeasonCalibrationCharge,
                contractMidSeasonCalibrationDescription: state.contractMidSeasonCalibrationDescription,
                contract12WeeklyBrakeRollerTests: state.contract12WeeklyBrakeRollerTests,
                contract12WeeklyBrakeRollerTestsCharge: state.contract12WeeklyBrakeRollerTestsCharge,
                contract12WeeklyBrakeRollerTestsDescription: state.contract12WeeklyBrakeRollerTestsDescription,
                contractBespoke: state.contractBespoke,
                contractBespokeCharge: state.contractBespokeCharge,
                contractBespokeDescription: state.contractBespokeDescription,
                vehiclesIDs: props.selectedRecords.map((p) => p.vehicleID)
            };

            let res = await postHireFleetContract(data);
            if (res.success) {
                showToastSuccess(res?.message);
                props.onClose(true, tempOptions.contractID?.id);
            } else {
                showToastError(`Fail to link contracts`);
            }
            setBtnLoader(false);
        }
    };

    return (
        <>
            {props.selectedRecords.length > 0 ? (
                <Grid container spacing={1} alignItems="flex-start">
                    <Grid item xs={12} sm={8} md={8} className="mandatory-fields">
                        <InputLabel required shrink>
                            Contract Reference {state.spreaderTypeName ? `(${state.spreaderTypeName})` : ''}
                        </InputLabel>
                        <SingleSelect
                            options={dropdown.contractsList}
                            value={tempOptions.contractID}
                            onChange={singleSelectChange('contractID')}
                            disabled={props.customerID}
                        />
                        <FormHelperText error>{state.errors.contractID}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink error required>
                            Contract Commencement
                        </InputLabel>
                        <DatePicker
                            value={state.contractStartDate && moment(state.contractStartDate).format('YYYY-MM-DD')}
                            onChange={inputChange}
                            name="contractStartDate"
                        />
                        <FormHelperText error>{state.errors.contractStartDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink>Contract Termination Date</InputLabel>
                        <DatePicker
                            value={state.contractEndDate && moment(state.contractEndDate).format('YYYY-MM-DD')}
                            onChange={inputChange}
                            name="contractEndDate"
                        />
                        <FormHelperText error>{state.errors.contractEndDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink error required>
                            Winter Term (Weeks)
                        </InputLabel>
                        <SimpleField onChange={inputChange} name="contractWinterTerms" value={state.contractWinterTerms} />
                        <FormHelperText error>{state.errors.contractWinterTerms}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink error required>
                            Winter Start date
                        </InputLabel>
                        <DatePicker
                            value={state.contractWinterStartDate && moment(state.contractWinterStartDate).format('YYYY-MM-DD')}
                            onChange={inputChange}
                            name="contractWinterStartDate"
                        />
                        <FormHelperText error>{state.errors.contractWinterStartDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink>Winter End date</InputLabel>
                        <DatePicker
                            value={state.contractWinterEndDate && moment(state.contractWinterEndDate).format('YYYY-MM-DD')}
                            onChange={inputChange}
                            name="contractWinterEndDate"
                        />
                        <FormHelperText error>{state.errors.contractWinterEndDate}</FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink>Extended Date</InputLabel>
                        <DatePicker
                            value={state.contractExtendedDate && moment(state.contractExtendedDate).format('YYYY-MM-DD')}
                            onChange={inputChange}
                            name="contractExtendedDate"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink error required>
                            Purchase Order
                        </InputLabel>
                        <SimpleField onChange={inputChange} name="contractPurchaseOrder" value={state.contractPurchaseOrder} />
                        <FormHelperText error>{state.errors.contractPurchaseOrder}</FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink error required>
                            Invoice Frequency
                        </InputLabel>
                        <SelectBox
                            name="contractInvoiceFrequency"
                            value={state.contractInvoiceFrequency}
                            onChange={inputChange}
                            List={[
                                {
                                    name: 'Daily',
                                    id: 'd'
                                },
                                {
                                    name: 'Weekly',
                                    id: 'w'
                                },
                                {
                                    name: '4 Weeks',
                                    id: '4'
                                },
                                {
                                    name: '52 Weeks',
                                    id: '5'
                                },
                                {
                                    name: 'Monthly',
                                    id: 'm'
                                },
                                {
                                    name: 'Yearly',
                                    id: 'y'
                                }
                            ]}
                        />
                        <FormHelperText error>{state.errors.contractInvoiceFrequency}</FormHelperText>
                    </Grid>
                    <Grid iitem xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink>Invoice All Year</InputLabel>
                        {/* <SimpleField onChange={inputChange} name="contractInvoiceAllYear" value={state.contractInvoiceAllYear} /> */}
                        <YesNoRadio name="contractInvoiceAllYear" value={state.contractInvoiceAllYear || false} onChange={inputChange} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink>Framework</InputLabel>
                        <SimpleField onChange={inputChange} name="contractFramework" value={state.contractFramework} />
                        <FormHelperText error>{state.errors.contractFramework}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        {/* <SimpleField onChange={inputChange} name="contractInvoiceAllYear" value={state.contractInvoiceAllYear} /> */}
                        <Grid container>
                            <Grid item xs={8}>
                                <InputLabel shrink>Annual Price Increase</InputLabel>
                                <YesNoRadio name="contractAnnualPriceIncrease" value={state.contractAnnualPriceIncrease || false} onChange={inputChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel shrink>Increase %</InputLabel>
                                <SimpleField onChange={inputChange} name="contractIncreaseAmount" value={state.contractIncreaseAmount} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                        <InputLabel shrink>Increase Date</InputLabel>
                        <DatePicker
                            value={state.contractIncreaseDate && moment(state.contractIncreaseDate).format('YYYY-MM-DD')}
                            onChange={inputChange}
                            name="contractIncreaseDate"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <hr />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={5} xl={6} className="mandatory-fields">
                        <InputLabel required shrink>
                            Depot
                        </InputLabel>
                        <SingleSelect
                            name="contractDepotID"
                            options={dropdown.depotsList || []}
                            value={tempOptions.contractDepotID}
                            onChange={singleSelectChange('contractDepotID')}
                        />
                        <FormHelperText error>{state.errors.contractDepotID}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg xl={2}>
                        <InputLabel shrink error required>
                            Service Area
                        </InputLabel>
                        <SingleSelect
                            name="contractServiceAreaID"
                            value={tempOptions.contractServiceAreaID}
                            onChange={singleSelectChange('contractServiceAreaID')}
                            options={dropdown.serviceAgentsList}
                        />
                        <FormHelperText error>{state.errors.contractServiceAreaID}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg xl={2}>
                        <InputLabel shrink>Engineer</InputLabel>
                        <SingleSelect
                            name="contractEngineerUserID"
                            value={tempOptions.contractEngineerUserID}
                            onChange={singleSelectChange('contractEngineerUserID')}
                            options={dropdown.technicianList}
                        />
                        <FormHelperText error>{state.errors.contractEngineerUserID}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg xl={2}>
                        <InputLabel shrink error required>
                            Summer Service Area
                        </InputLabel>
                        <SingleSelect
                            name="contractSummerServiceAreaID"
                            value={tempOptions.contractSummerServiceAreaID}
                            onChange={singleSelectChange('contractSummerServiceAreaID')}
                            options={dropdown.serviceAgentsList}
                        />
                        <FormHelperText error>{state.errors.contractSummerServiceAreaID}</FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <hr />
                    </Grid>
                    {/* <Grid item xs={12}>
                    <Grid container spacing={1}> */}
                    <Grid item xs={12} lg={4}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4} md={3} lg={6}>
                                <InputLabel shrink error required>
                                    Delivery Charge
                                </InputLabel>
                                <SimpleField
                                    type="number"
                                    onChange={inputChange}
                                    name="contractDeliveryCharge"
                                    value={state.contractDeliveryCharge}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {/* <EuroIcon color="secondary" /> */}
                                                <Typography color="secondary" variant="h6">
                                                    £
                                                </Typography>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <FormHelperText error>{state.errors.contractDeliveryCharge}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={6}>
                                <InputLabel shrink error required>
                                    Collection Charge
                                </InputLabel>
                                <SimpleField
                                    type="number"
                                    onChange={inputChange}
                                    name="contractCollectionCharge"
                                    value={state.contractCollectionCharge}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {/* <EuroIcon color="secondary" /> */}
                                                <Typography color="secondary" variant="h6">
                                                    £
                                                </Typography>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <FormHelperText error>{state.errors.contractCollectionCharge}</FormHelperText>
                            </Grid>
                            {/* <Grid item xs={12}>
                            contractOffHireAuthorityReference
                        </Grid> */}

                            <Grid item xs={12} sm={4} md={3} lg={6}>
                                <InputLabel shrink error required>
                                    Rate (Weekly)
                                </InputLabel>
                                <SimpleField
                                    type="number"
                                    onChange={inputChange}
                                    name="contractWeeklyRental"
                                    value={state.contractWeeklyRental}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {/* <EuroIcon color="secondary" /> */}
                                                <Typography color="secondary" variant="h6">
                                                    £
                                                </Typography>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <FormHelperText error>{state.errors.contractWeeklyRental}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={6}>
                                <InputLabel shrink>Rate Daily</InputLabel>
                                <SimpleField
                                    disabled
                                    // onChange={inputChange}
                                    name="contractWeeklyRental"
                                    // value={Math.round(state.contractWeeklyRental / 7).toFixed(2)}
                                    value={Number(state.contractWeeklyRental / 7).toFixed(2)}
                                    // (Math.round(num * 100) / 100).toFixed(2);
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {/* <EuroIcon color="secondary" /> */}
                                                <Typography color="secondary" variant="h6">
                                                    £
                                                </Typography>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3} lg={6}>
                                <InputLabel shrink>Mileage (KMs)</InputLabel>
                                <SimpleField type="number" onChange={inputChange} name="contractMileage" value={state.contractMileage} />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={6}>
                                <InputLabel shrink>Excess Mileage (pence per KM)</InputLabel>
                                <SimpleField onChange={inputChange} name="contractExcessMileage" value={state.contractExcessMileage} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={8} className="mandatory-fields">
                        <SimpleField
                            multiline
                            rows={8}
                            inputLabel="Notes"
                            disabled={state.disabled}
                            placeholder="Notes"
                            value={state.contractNotes}
                            onChange={inputChange}
                            name="contractNotes"
                            // errors={state.errors.contractDescription}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <hr />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Plough Required?</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio
                                                    name="contractPloughRequired"
                                                    value={state.contractPloughRequired || false}
                                                    onChange={inputChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contractPloughCharge"
                                                    value={state.contractPloughCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormHelperText error>{state.errors.contractPloughRequired}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Plough </InputLabel>
                                        <SimpleField onChange={inputChange} name="contractPloughDescription" value={state.contractPloughDescription} />
                                        <FormHelperText error>{state.errors.contractPloughDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Camera</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio onChange={inputChange} name="contractCamera" value={state.contractCamera} />
                                            </Grid>
                                            <Grid item xs>
                                                {' '}
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contractCameraCharge"
                                                    value={state.contractCameraCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormHelperText error>{state.errors.contractCamera}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Camera </InputLabel>
                                        <SimpleField onChange={inputChange} name="contractCameraDescription" value={state.contractCameraDescription} />
                                        <FormHelperText error>{state.errors.contractCameraDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Tracker</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio onChange={inputChange} name="contractTracker" value={state.contractTracker} />
                                            </Grid>
                                            <Grid item xs>
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contractTrackerCharge"
                                                    value={state.contractTrackerCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormHelperText error>{state.errors.contractTracker}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Tracker</InputLabel>
                                        <SimpleField onChange={inputChange} name="contractTrackerDescription" value={state.contractTrackerDescription} />
                                        <FormHelperText error>{state.errors.contractTrackerDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Navigation?</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio name="contractNavigation" value={state.contractNavigation || false} onChange={inputChange} />
                                            </Grid>
                                            <Grid item xs>
                                                {' '}
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contractNavigationCharge"
                                                    value={state.contractNavigationCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormHelperText error>{state.errors.contractNavigationCharge}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Navigation </InputLabel>
                                        <SimpleField onChange={inputChange} name="contractNavigationDescription" value={state.contractNavigationDescription} />
                                        <FormHelperText error>{state.errors.contractNavigationDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Onboard Weighing?</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio
                                                    name="contractOnboardWeighing"
                                                    value={state.contractOnboardWeighing || false}
                                                    onChange={inputChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contractOnboardWeighingCharge"
                                                    value={state.contractOnboardWeighingCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormHelperText error>{state.errors.contractOnboardWeighingCharge}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Onboard Weighing </InputLabel>
                                        <SimpleField
                                            onChange={inputChange}
                                            name="contractOnboardWeighingDescription"
                                            value={state.contractOnboardWeighingDescription}
                                        />
                                        <FormHelperText error>{state.errors.contractOnboardWeighingDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Temp Probe?</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio name="contractTempProbe" value={state.contractTempProbe || false} onChange={inputChange} />
                                            </Grid>
                                            <Grid item xs>
                                                {' '}
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contractTempProberCharge"
                                                    value={state.contractTempProberCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Temp Probe </InputLabel>
                                        <SimpleField onChange={inputChange} name="contractTempProberDescription" value={state.contractTempProberDescription} />
                                        <FormHelperText error>{state.errors.contractTempProberDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>MOT</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio onChange={inputChange} name="contractMOT" value={state.contractMOT} />
                                            </Grid>
                                            <Grid item xs>
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contractMOTCharge"
                                                    value={state.contractMOTCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormHelperText error>{state.errors.contractMOT}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>MOT </InputLabel>
                                        <SimpleField onChange={inputChange} name="contractMOTDescription" value={state.contractMOTDescription} />
                                        <FormHelperText error>{state.errors.contractMOTDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Mid Season Calibration?</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio
                                                    name="contractMidSeasonCalibration"
                                                    value={state.contractMidSeasonCalibration || false}
                                                    onChange={inputChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                {' '}
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contractMidSeasonCalibrationCharge"
                                                    value={state.contractMidSeasonCalibrationCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Mid Season Calibration </InputLabel>
                                        <SimpleField
                                            onChange={inputChange}
                                            name="contractMidSeasonCalibrationDescription"
                                            value={state.contractMidSeasonCalibrationDescription}
                                        />
                                        <FormHelperText error>{state.errors.contractMidSeasonCalibrationDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>12 Weekly Brake Roller Test?</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio
                                                    name="contract12WeeklyBrakeRollerTests"
                                                    value={state.contract12WeeklyBrakeRollerTests || false}
                                                    onChange={inputChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                {' '}
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contract12WeeklyBrakeRollerTestsCharge"
                                                    value={state.contract12WeeklyBrakeRollerTestsCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>12 Weekly Brake Roller Test </InputLabel>
                                        <SimpleField
                                            onChange={inputChange}
                                            name="contract12WeeklyBrakeRollerTestsDescription"
                                            value={state.contract12WeeklyBrakeRollerTestsDescription}
                                        />
                                        <FormHelperText error>{state.errors.contract12WeeklyBrakeRollerTestsDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Bespoke</InputLabel>
                                        <Grid container>
                                            <Grid item xs>
                                                <YesNoRadio onChange={inputChange} name="contractBespoke" value={state.contractBespoke} />
                                            </Grid>
                                            <Grid item xs>
                                                <SimpleField
                                                    type="number"
                                                    onChange={inputChange}
                                                    name="contractBespokeCharge"
                                                    value={state.contractBespokeCharge}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {/* <EuroIcon color="secondary" /> */}
                                                                <Typography color="secondary" variant="h6">
                                                                    £
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormHelperText error>{state.errors.contractBespoke}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel shrink>Bespoke </InputLabel>
                                        <SimpleField onChange={inputChange} name="contractBespokeDescription" value={state.contractBespokeDescription} />
                                        <FormHelperText error>{state.errors.contractBespokeDescription}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table size="small" width="550px" stickyHeader>
                                <TableHead>
                                    <TableRow className="header">
                                        <StyledTableCell>Reg Number</StyledTableCell>
                                        <StyledTableCell>Vehicle Type</StyledTableCell>
                                        <StyledTableCell>Service Area</StyledTableCell>
                                        <StyledTableCell>Technician</StyledTableCell>
                                        {/* <StyledTableCell>Start Date</StyledTableCell>
                                     <StyledTableCell>Onsite Date</StyledTableCell>
                                     <StyledTableCell>End</StyledTableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.selectedRecords.map((q) => {
                                        return (
                                            <StyledTableRow key={q.regNo}>
                                                <StyledTableCell>{q.regNo}</StyledTableCell>
                                                <StyledTableCell>{q.vehicleType}</StyledTableCell>
                                                <StyledTableCell>{q.serviceAgentName}</StyledTableCell>
                                                <StyledTableCell>{q.technician}</StyledTableCell>
                                                {/* <StyledTableCell>{formatters.DateTimeFormatter(q.startDate) || '-'}</StyledTableCell>
                                             <StyledTableCell>{formatters.DateTimeFormatter(q.onSiteDate) || '-'}</StyledTableCell>
                                             <StyledTableCell>{formatters.DateTimeFormatter(q.endDate) || '-'}</StyledTableCell> */}
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <SecondaryButton
                            onClick={handleSubmit}
                            className="submit_btn"
                            isBtnLoader={btnLoader}
                            disabled={!checkhaveAccess?.CanUpdateHireContracts}
                        >
                            Submit
                        </SecondaryButton>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Alert severity="info">Please Select Vehicles </Alert>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default HireFleetLink;
