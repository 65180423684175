// import React from 'react';
import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableContainer, TableRow, Paper, TableCell, Typography, TableHead } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { CustomChip } from '../../../../../Core/Controls';
import ContractField from './contractTab';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import { useHistory } from 'react-router-dom';

const gridStyle = {
    title: {
        color: '#f50057'
    },
    header: {
        border: '1px solid rgba(0,0,0,0.2)'
    },
    tbHead: {
        padding: '5px 0px 6px 8px'
    },
    tbContent: {
        margin: '8px 0px 14px 0px;'
    }
};

const ContractDash = (props) => {
    const history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const [show, setShow] = useState(false);
    const [state, setState] = useState({
        contractReference: '',
        contractType: '',
        spreaderTypeDesc: ''
    });
    useEffect(() => {
        if (props.vehicleContractID) {
            setShow(true);
        }
    }, []);

    const hadleScreen = () => {
        setShow(!show);
    };

    const RedirectToContract = useCallback(() => {
        try {
            history.push(`/contractList/editcontractList/${state.masterContractID}`);
        } catch (error) {
            console.error(error);
        }
    }, [state.masterContractID]);

    const getHeaderData = (data, vehicleContractData) => {
        console.log(data);
        setState((st) => ({
            ...st,
            contractReference: data?.contractReference || '',
            contractType: data?.contractType || '',
            spreaderTypeDesc: data?.spreaderTypeDesc || '',
            masterContractID: vehicleContractData.masterContractID
        }));
    };

    return (
        <div style={{ marginTop: 10, padding: 5 }}>
            {/* <ContractField onClose={hadleScreen} vehicleID={props.VehicleID} vehicleContractID={props.vehicleContractID} /> */}

            {/* <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <div style={gridStyle.header}>
                            <div style={gridStyle.title}>
                                <Grid container style={{ alignItems: 'center', padding: '0px 6px' }}>
                                    <Grid xs={12} lg={6} item style={{ alignItems: 'center' }}>
                                        <Typography variant="h6" color="secondary" style={gridStyle.tbHead}>
                                            Contract Details
                                            <span style={{ fontSize: '14px' }}>{` (Contract Ref: ${state.contractReference || 'N/A'} Contract Type: ${
                                                state.contractType || 'N/A'
                                            })`}</span>
                                        </Typography>
                                    </Grid>

                                    <Grid xs={12} lg={6} container item justify="flex-end">
                                        {!isCustomer && (
                                            <div style={{ float: 'right', display: props.vehicleContractID ? 'none' : 'block' }}>
                                                {!show && (
                                                    <CustomChip
                                                        style={{ marginRight: 17 }}
                                                        icon={AssignmentIcon}
                                                        label={props.vehicleContractID ? 'Contract Detail' : 'Set contract'}
                                                        onClick={hadleScreen}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Table aria-label="simple table" size="medium">
                            <TableBody>
                                <TableRow key={1}>
                                    <TableCell style={{ color: 'inherit' }} width="75%">
                                        {!show ? (
                                            <div style={{ textAlign: 'center' }}>
                                                {' '}
                                                Contract detail is not set up for this vehicle.&nbsp;
                                                {!isCustomer && (
                                                    <span onClick={hadleScreen} style={{ cursor: 'pointer', color: '#f50057' }}>
                                                        Click here to set contract details.
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <ContractField
                                                getHeaderData={getHeaderData}
                                                onClose={hadleScreen}
                                                vehicleID={props.VehicleID}
                                                vehicleContractID={props.vehicleContractID}
                                                pullDetails={props.pullDetails}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid> */}
            <Grid container spacing={1}>
                <Grid xs={12} item style={{ alignItems: 'center', padding: '5px 0px 6px 8px', border: '1px solid rgba(0,0,0,0.2)', marginBottom: '5px' }}>
                    <Typography variant="h6" color="secondary">
                        Contract Details{' '}
                        <span style={{ fontSize: '14px' }}>
                            <span onClick={RedirectToContract} style={{ cursor: 'pointer' }}>
                                <strong>Contract Ref:</strong> {state.contractReference || 'N/A'}
                            </span>
                            {` `}
                            <strong>Contract Type:</strong> {state.contractType || 'N/A'} <strong>Spreader Type:</strong> {state.spreaderTypeDesc || 'N/A'}
                        </span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ContractField
                        isStepperReload={props.isStepperReload}
                        getHeaderData={getHeaderData}
                        onClose={hadleScreen}
                        vehicleID={props.VehicleID}
                        vehicleContractID={props.vehicleContractID}
                        pullDetails={props.pullDetails}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default ContractDash;
