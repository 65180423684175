import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { Divider, FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { addUpdateNote, getNoteById, getNotesStickButtons, getAllNotes } from '../../../../Core/Services/InternalUser/ControllerService/noteService';
import { DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { useToast } from '../../../../Core/Hooks/useToast';
import moment from 'moment';
import { InputText, SecondaryButton, DateTimePicker, DataGrid, formatters, SecondaryCheckbox, SimpleField, CustomChip } from '../../../../Core/Controls';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const notecolumns = [
    { key: 'dateCreated', name: 'Created', width: 160, formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'createdBy', name: 'Created By', width: 130 },
    { key: 'note', name: 'Notes' }
];

const FlaggedBox = (props) => {
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} disabled={props.disabled} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={props.disabled || !props.chkValue}
                        type={props.fieldType || 'text'}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const AddDefectBreakEditJobsNotes = (props) => {
    const { showToastError, showToastSuccess } = useToast();

    const [btnLoader, setBtnLoader] = useState({ newBtnLoader: false, extBtnLoader: false });

    const [state, setState] = useState({
        show: false,
        showLoader: true,
        NoteList: [],
        // noteID: null,
        // noteTitle: props.notesTitle === 'Defects' ? 'Defect notes' : props.notesTitle === 'BreakDown' ? 'Breakdown notes' : '',
        // noteDescription: '',
        // noteActive: true,
        errors: {},
        newErrors: {},
        noteVehicleID: null,
        noteRefsID: null,
        // noteStickyDisplayType: '',
        // noteStickyDisplayFromDate: '',
        // noteStickyDisplayEndDate: moment().format('YYYY-MM-DDTHH:mm'),
        // noteStickyDisplayType: 'A',
        newNoteTitle: props.notesTitle === 'Defects' ? 'Defect notes' : props.notesTitle === 'BreakDown' ? 'Breakdown notes' : '',
        newNoteDescription: '',
        newNoteRefsGuidID: null,
        newNoteRefsID: null,
        newNoteStickyDisplayType: '',
        newNoteStickyDisplayFromDate: '',
        newNoteStickyDisplayEndDate: '',
        noteSendSMSReported: false,
        noteSendSMSReportedNo: props.notesTitle === 'Defects' || 'BreakDown' ? props.reportedPhone : '',
        driverName: props.driverName || '',
        newNoteSendSMS: false,
        newNoteSendSMSNumber: props.DisplayData || '',
        reportedBy: props.reportedBy || '',
        newNoteSendSMSReported: false,
        newNoteSendSMSReportedNo: props.reportedPhone || ''
    });

    useEffect(async () => {
        if (props.frompage) {
            setState((st) => ({ ...st, noteVehicleID: props.NoteVehicleID, noteRefsID: props.noteRefsID, noteRefsGuidID: props.noteRefsGuidID }));
        }
        if (props.NoteID) {
            let res = await getNoteById(props.NoteID, props.frompage, props.NoteVehicleID, props.noteRefsID);
            const Notedata = res.data;
            setState((st) => ({
                ...st,
                noteID: Notedata.noteID,
                noteActive: Notedata.noteActive,
                noteDescription: Notedata.noteDescription,
                noteTitle: Notedata.noteTitle,
                noteStickyDisplayType: Notedata.noteStickyDisplayType,
                noteStickyDisplayFromDate: Notedata.noteStickyDisplayFromDate ? moment(Notedata.noteStickyDisplayFromDate).format('YYYY-MM-DDTHH:mm') : '',
                noteStickyDisplayEndDate: Notedata.noteStickyDisplayEndDate
                    ? moment(Notedata.noteStickyDisplayEndDate).format('YYYY-MM-DDTHH:mm')
                    : moment().format('YYYY-MM-DDTHH:mm'),
                driverName: Notedata.driverName,
                noteSendSMSNumber: Notedata.driverPhone,
                newNoteSendSMSNumber: Notedata.driverPhone,
                reportedBy: Notedata.reportedBy,
                noteSendSMSReportedNo: Notedata.reportedPhone,
                newNoteSendSMSReportedNo: Notedata.reportedPhone
            }));
        }
    }, [props.NoteVehicleID]);

    const newValidations = () => {
        const { newNoteTitle, newNoteDescription, noteSendSMS, noteSendSMSNumber, newNoteStickyDisplayFromDate, noteSendSMSReported, noteSendSMSReportedNo } =
            state;
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(newNoteDescription)) {
            errors.newNoteDescription = 'The description field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(newNoteTitle)) {
            errors.newNoteTitle = 'The title field is required';
            formIsValid = false;
        }
        // if (!/[^\s]/.test(newNoteStickyDisplayFromDate)) {
        //     errors.newNoteStickyDisplayFromDate = 'The start date field is required';
        //     formIsValid = false;
        // }
        if (noteSendSMS) {
            if (!noteSendSMSNumber) {
                errors.noteSendSMSNumber = 'Phone Number is required';
                formIsValid = false;
            }
        }
        if (noteSendSMSReported) {
            if (!noteSendSMSReportedNo) {
                errors.noteSendSMSReportedNo = 'Phone Number is required';
                formIsValid = false;
            }
        }

        setState((st) => ({ ...st, newErrors: errors }));
        return formIsValid;
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const resetInput = (name) => {
        setState((st) => ({ ...st, [name]: '' }));
    };

    const handleNewNoteSubmit = async () => {
        if (newValidations()) {
            let formData;
            formData = {
                noteTitle: state.newNoteTitle,
                noteDescription: state.newNoteDescription,
                noteActive: state.noteActive,
                noteRefsGuidID: state.noteRefsGuidID,
                noteRefsID: state.noteRefsID,
                noteID: null,
                notefrompage: props.frompage,
                noteStickyDisplayType: 'D',
                noteStickyDisplayFromDate: moment().format('YYYY-MM-DDThh:mm') || '',
                noteStickyDisplayEndDate: moment().format('YYYY-MM-DDThh:mm') || ''
            };
            let number = '';
            if (state.newNoteSendSMS && state.newNoteSendSMSReported) {
                number = state.newNoteSendSMSReportedNo + ',' + state.newNoteSendSMSNumber;
            } else if (state.newNoteSendSMSReported) {
                number = state.newNoteSendSMSReportedNo;
            } else if (state.newNoteSendSMS) {
                number = state.newNoteSendSMSNumber;
            }
            if (props.frompage === 'Defects') {
                formData['noteSendSMSNumber'] = number;
                formData['noteSendSMS'] = state.newNoteSendSMS || state.newNoteSendSMSReported;
            }
            setBtnLoader((st) => ({ ...st, newBtnLoader: true }));
            let res = await addUpdateNote(formData);
            if (res.success) {
                showToastSuccess('Notes Added Successfully!');
                props.onFormSubmit(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader((st) => ({ ...st, newBtnLoader: false }));
        }
    };

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const RenderTitle = () => {
        return (
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={6} container justify="space-between">
                    <Grid item>
                        <DialogTitle style={{ padding: 0 }}>Next Update</DialogTitle>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={props.onClose} style={{ marginRight: 7, padding: 0 }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    return (
        <DialogComp title={`New  Notes`} onClose={props.onClose} maxWidth="md" overflow="auto">
            <Grid container spacing={2} alignItems="flex-start" style={{ overflow: 'hidden', height: 'none' }}>
                <Grid item container xs={12} sm={12} spacing={2}>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>Notes</InputLabel>
                        <InputText
                            multiline
                            rows={3}
                            placeholder="Reminder Notes"
                            value={state.newNoteDescription}
                            onChange={inputChange}
                            name="newNoteDescription"
                            autoFocus={true}
                        />
                        <FormHelperText error>{state.newErrors.newNoteDescription}</FormHelperText>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>Reminder Start Date & Time</InputLabel>
                        <DateTimePicker
                            placeholder="Reminder Start Date & Time"
                            value={state.newNoteStickyDisplayFromDate}
                            onChange={inputChange}
                            name="newNoteStickyDisplayFromDate"
                        />
                        <FormHelperText error>{state.newErrors.newNoteStickyDisplayFromDate}</FormHelperText>
                    </Grid> */}
                    {props.frompage == 'Defects' && (
                        <>
                            {state.driverName && (
                                <Grid item xs={12} style={{ marginBottom: 8 }}>
                                    <FlaggedBox
                                        label={`Send text to ${state.driverName} (Driver)`}
                                        chkName="newNoteSendSMS"
                                        chkValue={state.newNoteSendSMS}
                                        onCheckChange={handleCheckbox}
                                        txtName="newNoteSendSMSNumber"
                                        txtValue={state.newNoteSendSMSNumber}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.newErrors.newNoteSendSMSNumber}</FormHelperText>
                                </Grid>
                            )}
                            {state.reportedBy && (
                                <Grid item xs={12}>
                                    <FlaggedBox
                                        label={`Send text to ${state.reportedBy} (Reported By)`}
                                        chkName="newNoteSendSMSReported"
                                        chkValue={state.newNoteSendSMSReported}
                                        onCheckChange={handleCheckbox}
                                        txtName="newNoteSendSMSReportedNo"
                                        txtValue={state.newNoteSendSMSReportedNo}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.newErrors.newNoteSendSMSReportedNo}</FormHelperText>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <SecondaryButton isBtnLoader={btnLoader.newBtnLoader} onClick={() => handleNewNoteSubmit(true)} fullWidth>
                        Create New Notes
                    </SecondaryButton>
                </Grid>
            </Grid>
        </DialogComp>
    );
};

export default AddDefectBreakEditJobsNotes;
