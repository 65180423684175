import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import {
    InputText,
    SecondaryButton,
    CustomColorButton,
    DatePicker,
    DateTimePicker,
    SelectBox,
    SingleSelect,
    formatters,
    SimpleField
} from '../../../../Core/Controls';
import SignaturePad from 'react-signature-canvas';
import './defect.scss';
import moment from 'moment';
import { getAllScheduleDropdowns } from '../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import {
    postAgentArrivalTime,
    postTechnicianSignOff,
    postVehicalRecoveredTime,
    postAdministratorSignOff,
    postWorkinProgress
} from '../../../../Core/Services/InternalUser/Maintenance/DefectBreackDownStepper/DefectBreackDownStepper';
import { useToast } from '../../../../Core/Hooks/useToast';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
import BuildIcon from '@material-ui/icons/Build';
import ServiceAgentDetails from '../../Controllers/DetailsModal/serviceAgent';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const ServiceAgentArrivedDate = (props) => {
    const [state, setState] = useState({
        // arrivedDate: props.isDateMandatoey ? moment().format('YYYY-MM-DDTHH:mm') : '',
        arrivedDate: '',
        repairNotes: '',
        errors: {},
        serviceAgentID: props.jobData.jobServiceAgentID,
        serviceAgentIDValue: null,
        eTA: props.jobData?.defectDateETA || ''
    });
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let newSt = {};
        // let res = await getAllScheduleDropdowns(props.userData.defectVehicleID || props.userData.vehicleID);
        if (state.serviceAgentID && props.userData?.saList.length > 0) {
            newSt.serviceAgentIDValue = props.userData?.saList.find(({ id }) => id === state.serviceAgentID);
        }
        if (props.fromPage === 'serviceAgent') {
            newSt.arrivedDate = props.jobData?.defectDateAgentArrived || '';
        }
        if (props.fromPage === 'workInProgress') {
            newSt.arrivedDate = props.jobData?.defectDateWorkInProgress || '';
        }
        setState((st) => ({
            ...st,
            serviceAgentsList: props.userData?.saList,
            ...newSt
        }));
    }, []);

    const dateFieldsChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        const { serviceAgentIDValue } = state;
        let formIsValid = true;
        let errors = {};
        if (!serviceAgentIDValue && props.fromPage === 'serviceAgent') {
            errors.serviceAgentIDValue = ' This field is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async (isValue) => {
        let FormData;
        if (validations()) {
            if (props.fromPage === 'serviceAgent') {
                FormData = {
                    defectID: props.defectID,
                    // jobID: props.jobData.vehicleJobProgressJobID,
                    arrivedDate: state.arrivedDate,
                    serviceAgentID: state.serviceAgentIDValue?.id,
                    eTA: state.eTA || ''
                };
                let res = await postAgentArrivalTime(FormData);
                if (res.success) {
                    showToastSuccess(`Service Area arrived added successfully`);
                    props.onClose(true);
                } else {
                    showToastError(res.message);
                }
            }
            if (props.fromPage === 'workInProgress') {
                FormData = {
                    defectID: props.defectID,
                    // jobID: props.jobData.vehicleJobProgressJobID,
                    vehicleJobProgressDateWorkInProgress: state.arrivedDate,
                    status: isValue,
                    repairNotes: state.repairNotes
                };

                let res = await postWorkinProgress(FormData);
                if (res.success) {
                    props.onClose(true);
                } else {
                    showToastError(res.message);
                }
            }
            if (props.fromPage === 'vehicleArrived') {
                FormData = {
                    defectID: props.defectID,
                    // jobID: props.jobData.vehicleJobProgressJobID,
                    defectDateVehicleRecovered: state.arrivedDate,
                    defectLocation: state.defectLocation
                };
                let res = await postVehicalRecoveredTime(FormData);
                if (res.success) {
                    showToastSuccess(`Vehicle arrived added successfully`);
                    props.onClose(true);
                } else {
                    showToastError(res.message);
                }
            }
        }
    };

    const closeServiceAgentModal = (res) => {
        setState((st) => ({
            ...st,
            showSaModal: false
        }));
    };

    const handleShowServiceAgentModal = (res) => {
        setState((st) => ({
            ...st,
            showSaModal: true
        }));
    };

    const sATitle = props.jobData?.vehicleJobProgressDateAgentAssigned ? 'Set Arrived Date' : 'Assign Service Area';
    const { vehicleJobProgressStatusCode, vehicleJobProgressDateWorkInProgress, recoveryRequired } = props.jobData;
    let WorkInTitle = vehicleJobProgressStatusCode == 'WP' ? 'Work Completed Date' : vehicleJobProgressStatusCode == 'AR' ? 'Work Started Date' : 'Date';
    let showDateNdBtn = recoveryRequired;
    return (
        <Grid container spacing={2}>
            {props.fromPage === 'serviceAgent' ? (
                <>
                    <Grid item xs={11} className="mandatory-fields">
                        <InputLabel shrink>Service Area</InputLabel>
                        <SingleSelect
                            options={state.serviceAgentsList || []}
                            value={state.serviceAgentIDValue}
                            onChange={singleSelectChange('serviceAgentIDValue')}
                        />
                        <FormHelperText error>{state.errors.serviceAgentIDValue}</FormHelperText>
                        {props.jobData?.vehicleJobProgressDateAgentAssigned ? (
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Assigned Date:</FormHelperText>
                                <Typography variant="button">{formatters.DateTimeFormatter(props.jobData?.vehicleJobProgressDateAgentAssigned)}</Typography>
                            </Grid>
                        ) : null}
                    </Grid>
                    {state.serviceAgentIDValue && (
                        <Grid item xs={1} style={{ paddingTop: '28px' }}>
                            <ChipWithoutLable
                                icon={HourglassEmptyIcon}
                                iconFontSize={16}
                                onClick={handleShowServiceAgentModal}
                                className="btn-m"
                                toolTipTitle={'Service Area Details'}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <InputLabel shrink>Estimated Arrival Time</InputLabel>
                        <DateTimePicker
                            placeholder="Estimated Arrival Time"
                            value={state.eTA}
                            onChange={dateFieldsChange}
                            name="eTA"
                            InputProps={{ inputProps: { max: `${state.eTA}` } }}
                        />
                        <FormHelperText error></FormHelperText>
                    </Grid>
                </>
            ) : null}
            {props.fromPage === 'vehicleArrived' ? (
                <Grid item xs={12}>
                    <InputLabel shrink>Recovery location</InputLabel>
                    <InputText
                        name="defectLocation"
                        placeholder="Recovery location"
                        defaultValue={state.defectLocation || ''}
                        value={state.defectLocation || ''}
                        onChange={dateFieldsChange}
                    />
                    <FormHelperText error>{state.errors.defectLocation ? 'Negative value not allowed' : null}</FormHelperText>
                </Grid>
            ) : null}
            {/* {showDateNdBtn && (
                <Grid item xs={12}>
                    <InputLabel shrink>Work Started</InputLabel>
                    <Typography>{formatters.DateTimeFormatter(vehicleJobProgressDateWorkInProgress)}</Typography>
                </Grid>
            )} */}
            <Grid item xs={12}>
                <InputLabel shrink> {props.fromPage === 'serviceAgent' ? 'Arrival Time' : WorkInTitle}</InputLabel>
                <DateTimePicker
                    placeholder={`${props.fromPage === 'serviceAgent' ? 'Arrival Time' : WorkInTitle}`}
                    value={state.arrivedDate}
                    onChange={dateFieldsChange}
                    name="arrivedDate"
                    InputProps={{ inputProps: { max: `${state.arrivedDate}` } }}
                />
                <FormHelperText error></FormHelperText>
            </Grid>
            {props.fromPage === 'workInProgress' ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SimpleField
                            multiline
                            rows={6}
                            inputLabel="Repair Notes"
                            name="repairNotes"
                            value={state.repairNotes}
                            onChange={dateFieldsChange}
                            placeholder="Repair Notes"
                        />
                    </Grid>
                    {!showDateNdBtn && (
                        <>
                            <Grid item xs={12}>
                                <SecondaryButton fullWidth className="submit_btn" onClick={() => SubmitHandler(2)}>
                                    Recovery Required
                                </SecondaryButton>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={6}>
                        <CustomColorButton fullWidth className="submit_btn" onClick={() => SubmitHandler(recoveryRequired ? 5 : 1)} BtnColor="green">
                            Problem Fixed
                        </CustomColorButton>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton fullWidth className="submit_btn" onClick={() => SubmitHandler(recoveryRequired ? 4 : 3)}>
                            Work In progress
                        </SecondaryButton>
                    </Grid>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <SecondaryButton fullWidth className="submit_btn" onClick={SubmitHandler}>
                        {props.fromPage === 'serviceAgent' ? sATitle : 'Set vehicle recovered time'}
                    </SecondaryButton>
                </Grid>
            )}
            {/* <Grid item xs={12}>
                <SecondaryButton fullWidth className="submit_btn" onClick={SubmitHandler}>
                    {props.fromPage === 'serviceAgent' ? 'Set agent arrival time' : 'Set vehicle recovered time'}
                </SecondaryButton>
            </Grid> */}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={() => closeServiceAgentModal(false)} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.serviceAgentIDValue?.id} onFormSubmit={() => closeServiceAgentModal(false)} />
                </DialogComp>
            ) : null}
        </Grid>
    );
};

export default ServiceAgentArrivedDate;
