import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { ExpBaseURL } from '../../Basic/HttpService';
import { AppStorage } from '../../Basic/storage-service';
import './gijgoGrid.scss';
import FileUploaderButton from '../GenericFileUploader'; //'../GenericFileUploader';
import { isValidConfig } from '../GenericFileUploader/FileUploaderConfig';
import ReactDOM from 'react-dom';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import { useToast } from '../../Hooks/useToast';
import { AddButton, EditButton, DataGrid, BreadCrumbs, formatters } from '../';
import { CustomChip, ChipWithoutLable } from '../Inputs/DatePicker';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { getAllGridScheduleDropdowns } from '../../Services/InternalUser/ControllerService/scheduleVehicleService';
import { getGrid_DropDowns } from '../../Services/InternalUser/ControllerService/vehicleService';

var grid1;
var $ = window.$;
let storedFiltterValue = {};
let storedFiltterLimit = JSON.parse(localStorage.getItem('_GijgoFiltterParams_'))?.limit || 100;
let dropDownConfig = {
    'Service Area': 'serviceAgents',
    ['Engineer']: 'engineers',
    Event: 'scheduleEvents'
};
var GijgoFilterExtension = (function () {
    function DropdownFilter(grid, inputProperty, dropdown, changeValueConversion) {
        var id = $(grid).attr('id');
        var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
        var filterRow = $(rowXPath);
        if ($(filterRow).length == 1) {
            var colXPath = 'th [data-field="' + inputProperty.key + '"]';
            var filterInput = $(filterRow).find(colXPath);
            if ($(filterInput).length) {
                $(filterInput).parent().addClass('grid-filter-select-th');
                $(dropdown).attr('data-field', inputProperty.key);
                $(filterInput).replaceWith(dropdown);
                $(dropdown).on('change', function () {
                    ChangeFilter(grid, inputProperty, $(this), changeValueConversion);
                });
            }
        }
    }

    function ChangeFilter(grid, inputProperty, ddl, changeValueConversion) {
        if (changeValueConversion) {
            var propArr = changeValueConversion(ddl, inputProperty.key, grid);
            $(propArr).each(function (i, obj) {
                grid.data().params[obj.inputProperty.key] = obj.value;
            });
            if (propArr.length) {
                grid.reload();
            }
        } else {
            grid.data().params[`${inputProperty.queryParamName || inputProperty.key}`] = $(ddl).val();
            grid.reload();
        }
    }

    return {
        Dropdown: DropdownFilter
    };
})();

const GijgoGrid = (props) => {
    let dropDownList = {};
    const { userRoles } = useLoginInfo();

    const isEngineer = userRoles.includes('engineer');

    let isAction = props.columns.find((c) => c.key === 'action');

    const useStyles = makeStyles((theme) => ({
        gijgoGrid: {
            '& .gj-grid-md th:first-of-type': {
                padding: (props) => {
                    return props.isAction ? '12px 5px 12px 5px' : '12px 18px 12px 13px';
                }
            }
        }
    }));

    // useEffect(() => {
    //     var timer = setInterval(() => {
    //         setTime(new Date());
    //     }, 3000);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    const classes = useStyles({ isAction: isAction });
    let token = AppStorage.getToken();
    const ut = useToast();
    const uli = useLoginInfo();
    const [state, setState] = useState({
        recordId: props.recordId,
        fileCount: `${props.fileCount || 0}`,
        configName: props.fileUploaderConfigName,
        columns: [],
        id: 'temp_id'

        // dropDownList: {}
    });
    const [gridReload, setGridReload] = useState(new Date());
    const [dropDownColNamesList, setDropDownColNamesList] = useState([]);

    const gridFileCountRefresh = () => {
        setGridReload((st) => ({ ...st, gridReload: new Date() }));
    };

    useEffect(async () => {
        let dropDownColNames = [];

        props.columns.forEach((c) => {
            if (c.name == 'Service Area' || c.name == 'Engineer' || c.name == 'Event' || c.filterDropDownColName) {
                dropDownColNames.push(c);
            }
        });

        setDropDownColNamesList(dropDownColNames);
    }, [props.columns]);

    useEffect(() => {
        let finalColumns = [];
        finalColumns = props.columns.map((c) => {
            if (c.key === 'action') {
                isAction = true;
                return {
                    ...c,
                    events: c.events,
                    field: c.key,
                    title: c.name,
                    width: c.width,
                    // ...(c.formatter && {
                    //     renderer: (value, row) => c.formatter(value, row)
                    // }),
                    renderer: (value, row) => (c.formatter ? c.formatter(value, row) : value),
                    addFileUploaderIcon: c.addFileUploaderIcon
                    // filterable: false
                };
            } else {
                return {
                    ...c,
                    events: c.events,
                    field: c.key,
                    title: c.name,
                    width: c.width,
                    ...(c.formatter && {
                        renderer: (value, row) => c.formatter({ row, column: { key: c.key, name: c.name } }, { isGijgo: true })
                    }),
                    filterable: c.filterable !== 'no',
                    sortable: c.sortable !== false, // Simplified check for true or undefined
                    align: c.align || 'left'
                };
            }
        });

        let cols = finalColumns.map((c) => ({ ...c, headerCssClass: 'gridHeader' }));
        if (cols.length > 0 && props.idFieldName && props.fileUploaderConfigName && isValidConfig(props.fileUploaderConfigName)) {
            const actionCol = cols.find((c) => c.addFileUploaderIcon);
            if (actionCol) {
                const prevFor = actionCol.renderer;
                var newFormatter = (params, val) => {
                    const spn2 = document.createElement('span');
                    const lnk2 = (
                        <Grid
                            container
                            // justify="space-evenly"
                            alignItems="center"
                        >
                            {(!isEngineer || props.forceToShowFileUpload) && (
                                <Grid item style={{ marginRight: '12px', marginLeft: '5px' }}>
                                    {
                                        <FileUploaderButton
                                            ut={ut}
                                            uli={uli}
                                            fileCount={val.fileCount}
                                            recordId={val[props.idFieldName]}
                                            fileUploaderConfigName={props.fileUploaderConfigName}
                                            gridRelaod={gridFileCountRefresh}
                                            fileUploaderModalClose={props.fileUploaderModalClose}
                                            docIDPassed={props.idFieldName == 'scheduleDocID' ? true : false}
                                        />
                                    }
                                </Grid>
                            )}
                            <Grid item>{prevFor(params, val)}</Grid>
                        </Grid>
                    );
                    ReactDOM.render(lnk2, spn2);
                    return spn2;
                };
                actionCol.renderer = newFormatter;
            }
        } else {
            const actionCol = cols.find((c) => c.field == 'action');
            if (actionCol) {
                const prevFor = actionCol.renderer;
                var newFormatter = (params, val) => {
                    const spn2 = document.createElement('span');
                    const lnk2 = prevFor(params, val);
                    ReactDOM.render(lnk2, spn2);
                    return spn2;
                };
                actionCol.renderer = newFormatter;
            }
        }
        setState((st) => ({ ...st, columns: cols }));
    }, [props.columns, gridReload]);

    useEffect(async () => {
        if (state.columns.length > 0 && (props.getUrl || props.localRowsData?.length > 0)) {
            pullArchivedJobsAndUpdateState();
        }
    }, [state.columns, props.isReload, props.getUrl, props.localRowsData]);
    const setDataSource = (gridInstance, getUrl = null) => {
        if (getUrl) {
            const onSuccessFunc = function (response) {
                gridInstance.render(props.dataConverter(response));
            };

            gridInstance.data().dataSource = {
                url: `${ExpBaseURL}/api/${getUrl}`,
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: onSuccessFunc
            };
            gridInstance.reload();
        }
        if (props.localRowsData) {
            gridInstance.data().dataSource = props.localRowsData;

            gridInstance.reload();
        }
    };

    const pullArchivedJobsAndUpdateState = async () => {
        if (dropDownColNamesList.length > 0) {
            let dropDownRes = await getGrid_DropDowns();
            dropDownList = dropDownRes.data || [];
        }

        window.$(`#${state.id}`).grid('destroy', true, true);

        grid1 = window.$(`#${state.id}`).grid({
            dataSource: [],
            ...(props.minWidth !== null && { minWidth: 1200 }),
            // fixedHeader: true,
            headerFilter: {
                type: 'onchange'
            },
            ...(props.selectionType && { selectionType: props.selectionType }),
            ...(props.selectionMethod && { selectionMethod: props.selectionMethod }),
            columns: [...state.columns],
            pager: {
                limit: storedFiltterLimit || 100,
                position: 'left'
            }
        });

        if (dropDownColNamesList.length > 0) {
            dropDownColNamesList.forEach((q) => {
                var ddl = $('<select class="grid-filter-select"><option value="" >All</option></select>');
                dropDownList[`${dropDownConfig[q.name] || q.filterDropDownColName}`]?.forEach((k) => {
                    let value = k['name'];
                    if (value === undefined || value === null) {
                        console.error(`${q.id} Invalid Property`);
                    }
                    ddl.append(`<option value="${value}" >${k.name}</option>`);
                });
                GijgoFilterExtension.Dropdown(grid1, q, ddl);
            });
        }

        setTimeout(() => {
            let locationName = `${window.location.pathname}`;
            let storedFiltterValue = localStorage.getItem('_GijgoFiltterParams_');
            let parsedFiltterValue = storedFiltterValue ? JSON.parse(storedFiltterValue) : {};
            storedFiltterValue = parsedFiltterValue[locationName] || {};

            props.columns.forEach((itm) => {
                if (storedFiltterValue) {
                    var id = $(grid1).attr('id');
                    var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
                    var filterRow = $(rowXPath);
                    if ($(filterRow).length == 1) {
                        var colXPath = 'th [data-field="' + `${itm.key}` + '"]';
                        var filterInput = $(filterRow).find(colXPath);
                        if ($(filterInput).length && storedFiltterValue[`${itm.key}`]) {
                            $(filterInput).val(storedFiltterValue[`${itm.key}`]);
                            grid1.data().params[itm.key] = storedFiltterValue[`${itm.key}`];
                        }
                    }
                }
            });

            if (storedFiltterValue.sortBy && storedFiltterValue.direction) {
                grid1.data().params.sortBy = storedFiltterValue.sortBy;
                grid1.data().params.direction = storedFiltterValue.direction;
            }
            if (storedFiltterValue.limit) {
                grid1.data().params.limit = storedFiltterValue.limit;
                storedFiltterLimit = storedFiltterValue.limit;
            }
            setDataSource(grid1, props.getUrl);
        });

        grid1.on('dataBound', function (e, records, totalRecords) {
            let storedData = JSON.parse(localStorage.getItem('_GijgoFiltterParams_')) || {};
            let { page, limit, ...rest } = grid1.data().params;
            storedData[`${window.location.pathname}`] = { ...storedData[`${window.location.pathname}`], ...rest };

            storedData.page = page;
            storedData.limit = limit;
            storedFiltterLimit = storedData.limit || limit;

            localStorage.setItem('_GijgoFiltterParams_', JSON.stringify(storedData));

            const gridActionSpn = document.createElement('span');
            const gridActionGrid = (
                <Grid container justify="space-around">
                    {props.btnTitle && (
                        <Grid item className="height-45">
                            <ChipWithoutLable icon={AddIcon} onClick={props.onBtnClick} style={{ fontWeight: 'bold' }} toolTipTitle={props.btnTitle} />
                        </Grid>
                    )}
                    {props.secondBtnTitle && (
                        <Grid item className="height-45">
                            <ChipWithoutLable
                                icon={props.secondBtnIcon}
                                onClick={props.onSecondBtnClick}
                                style={{ fontWeight: 'bold' }}
                                toolTipTitle={props.secondBtnTitle}
                            />
                        </Grid>
                    )}
                    {props.btnToRender &&
                        props.btnToRender.length > 0 &&
                        props.btnToRender.map((m) => {
                            return (
                                m.btnTitle && (
                                    <Grid item className="height-45">
                                        <ChipWithoutLable icon={m?.btnIcon} onClick={m?.onBtnClick} style={{ fontWeight: 'bold' }} toolTipTitle={m?.btnTitle} />
                                    </Grid>
                                )
                            );
                        })}
                    {props.mapBtnToolTipTitle && (
                        <Grid item className="height-45">
                            <ChipWithoutLable
                                icon={props.mapBtnIcon}
                                onClick={props.onMapBtnClick}
                                style={{ fontWeight: 'bold' }}
                                toolTipTitle={props.mapBtnToolTipTitle}
                            />
                        </Grid>
                    )}
                </Grid>
            );
            ReactDOM.render(gridActionGrid, gridActionSpn);
            var gridActionSpnRef = gridActionSpn;
            if (props.showCheckboxColFilterBtn) {
                // $(`#${state.id}`).find('thead tr[data-role="caption"]> th:nth-child(1)').attr('width', '7%');

                // .removeAttr('width').attr('style', 'width: 10px !important');
                // console.log(a, 'oooooo');
                $(`#${state.id}`)
                    .find('thead tr[data-role="filter"] > th:nth-child(1)')
                    .html(() => props.showCheckboxColFilterBtn(`#${state.id}`, records));
            }
            GijgoActionBtn(grid1, 'action', gridActionSpnRef);
        });

        grid1.on('rowSelect', function (e, $row, id, record) {
            props.onRowSelect && props.onRowSelect(e, $row, id, record);
        });

        grid1.on('rowUnselect', function (e, $row, id, record) {
            props.onRowUnSelect && props.onRowUnSelect(e, $row, id, record);
        });

        grid1.on('rowDataBound', function (e, $row, id, record) {
            props.onRowDataBound && props.onRowDataBound(e, $row, id, record, grid1);
        });

        grid1.on('cellDataBound', function (e, $displayEl, id, column, record) {
            props.onCellDataBound && props.onCellDataBound(e, $displayEl, id, column, record);
            // $displayEl.css("color", "white");
        });
    };

    const GijgoActionBtn = (grid, propertyName, dropdown) => {
        var id = $(grid).attr('id');
        var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
        var filterRow = $(rowXPath);
        if ($(filterRow).length == 1) {
            var colXPath = 'th [data-field="' + `action` + '"]';
            var filterInput = $(filterRow).find(colXPath);
            if ($(filterInput).length) {
                $(filterInput).replaceWith(dropdown);
            }
        }
    };
    const downloadCSVWrapper = async () => {
        if (props.downloadCustomUrl) {
            props.downloadCustomUrl(grid1.data().params);
        } else {
            grid1?.downloadCSV(`${props.downloadBtnName}-${moment().format('DD-MMM-YYYY HH:mm')}.csv`);
        }
    };
    return (
        <Grid
            container
            // style={{ position: 'relative' }}
        >
            <Grid container item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }} className={classes.gijgoGrid}>
                {props.downloadBtnName && (
                    <Grid
                        item
                        // style={{ top: '23px', left: '18px', zIndex: 99, position: 'absolute' }}
                        style={{ bottom: '5px', left: '10px', zIndex: 99, position: 'absolute' }}
                    >
                        <Tooltip title={`${props.downloadBtnName ? props.downloadBtnName : 'Download CSV'}`} arrow>
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
                <table id={`${state.id}`}></table>
            </Grid>
        </Grid>
    );
};

export default GijgoGrid;
