import {
    Button,
    ButtonGroup,
    CircularProgress,
    ClickAwayListener,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    makeStyles,
    Popover,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    withStyles
} from '@material-ui/core';
import { DatePicker, formatters, MandatoryField, PrimaryButton, SecondaryButton, SelectBox, SimpleField } from '../../../../../../Core/Controls';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import { GetAllPartList, GetAllPartsByPartNumber } from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import { Alert } from '@material-ui/lab';
import { Backup } from '@material-ui/icons';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import ConfirmationModal from '../../../../../../Core/Modal/ConfirmationModal';
import { useWindowSize } from '../../../../../../Core/Hooks/useWindowSize';
import CloseIcon from '@material-ui/icons/Close';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.white,
        padding: '10px 5px'
    },
    body: {
        fontSize: 15,
        padding: '2px 5px',
        color: theme.palette.common.black
    }
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '500px',
        backgroundColor: 'white'
    },
    popover: {
        pointerEvents: 'none'
    }
});

export const JobItemPurchaseParts = ({
    state,
    handleInputChange,
    handleFilter,
    handleImage,
    clearImage,
    addPartToLocally,
    patchSelectedRow,
    pullPurchasedPart,
    selectedPurchaedPart
}) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const [partState, setPartState] = useState({ matchingPartNumber: [], errors: {}, allPartsDataList: [] });
    const { showToastSuccess, showToastError } = useToast();
    const [ScreenWidth, ScreenHeight] = useWindowSize();

    useEffect(async () => {
        if (selectedPurchaedPart.isLocallyUpdated || selectedPurchaedPart?.vehicleJobPartID) {
            pullPurchasedPart(selectedPurchaedPart.vehicleJobPartID, selectedPurchaedPart);
        }
        const res = await GetAllPartList();
        setPartState((st) => ({ ...st, allPartsDataList: res.data || [] }));
    }, []);

    const validate = () => {
        let { partDescription, partQuantity } = state;
        let noErrors = true;
        let err = {};

        if (!partDescription) {
            err.partDescription = 'This is required';
            noErrors = false;
        }

        if (!partQuantity) {
            err.partQuantity = 'This is required';
            noErrors = false;
        }

        setPartState((st) => ({ ...st, errors: err }));
        return noErrors;
    };

    const confirmAndSubmit = () => {
        if (validate()) {
            if (!state.vehicleJobPartImage) {
                setPartState((st) => ({ ...st, showConfirmationModal: true }));
            } else {
                addPartToLocally(selectedPurchaedPart?.vehicleJobPartID, selectedPurchaedPart.id);
            }
        }
    };

    const handleImageModal = () => {
        setPartState((st) => ({ ...st, showImageModal: true }));
    };

    const filterDataByPartNumberDesc = (e) => {
        handleInputChange(e);

        let filteredData = [];
        if (e.target.value.length > 1) {
            if (e.target.name == 'partNumber') {
                filteredData = partState.allPartsDataList.filter((part) => part.pn.toLowerCase().includes(e.target.value.toLowerCase()));
            } else {
                filteredData = partState.allPartsDataList.filter((part) => part.pd.toLowerCase().includes(e.target.value.toLowerCase()));
            }

            setPartState((st) => ({
                ...st,
                matchingPartNumber: filteredData,
                loading: false
                // openModal: true // Uncomment this if you want to open a modal
            }));
            handlePopperOpen(e);
            if (filteredData.length == 0) {
                showToastError('No matching data found');
            }
            setPartState((st) => ({ ...st, loading: false }));
        } else {
            setPartState((st) => ({
                ...st,
                matchingPartNumber: filteredData,
                loading: false
                // openModal: true // Uncomment this if you want to open a modal
            }));
        }
    };

    const closeConfirmationModal = () => {
        setPartState((st) => ({ ...st, showConfirmationModal: false, showImageModal: false }));
    };

    const handlePopperOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handlePopperClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };
    const patchSelectedRowWrapper = (row) => {
        patchSelectedRow(row);
        handlePopperClose();
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={8} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <SimpleField
                                autoFocus
                                isDisabled={state.readOnly}
                                inputLabel="Part Number"
                                name="partNumber"
                                placeholder="Part Number"
                                onChange={filterDataByPartNumberDesc}
                                value={state.partNumber}
                                errors={partState.errors.partNumber}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={8} className="mandatory-fields">
                            <MandatoryField
                                isDisabled={state.readOnly}
                                inputLabel="Part Description"
                                name="partDescription"
                                placeholder="Part Description"
                                onChange={filterDataByPartNumberDesc}
                                value={state.partDescription}
                                errors={partState.errors.partDescription}
                            />
                        </Grid>

                        <Grid item xs={6} sm={2} md={4} lg={4}>
                            <MandatoryField
                                isDisabled={state.readOnly}
                                inputLabel="Quantity"
                                name="partQuantity"
                                type="number"
                                placeholder="Qty"
                                onChange={handleInputChange}
                                value={state.partQuantity}
                                autoFocus={state.focus}
                                errors={partState.errors.partQuantity}
                            />
                        </Grid>

                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <SimpleField
                                isDisabled={state.readOnly}
                                inputLabel="Retail Price"
                                name="retailPrice"
                                type="number"
                                placeholder="Price"
                                onChange={handleInputChange}
                                value={state.retailPrice}
                                errors={partState.errors.retailPrice}
                            />
                        </Grid>

                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <SimpleField
                                isDisabled={state.readOnly}
                                inputLabel="Cost Price"
                                name="partUnitPrice"
                                type="number"
                                placeholder="Price"
                                onChange={handleInputChange}
                                value={state.partUnitPrice}
                                errors={partState.errors.partUnitPrice}
                            />
                        </Grid>

                        <Grid item className="date_range" xs={6} sm={4} md={4} lg={4}>
                            <InputLabel shrink>Date</InputLabel>
                            <DatePicker
                                placeholder="Date"
                                value={state.vehicleJobPartDatePurchased}
                                onChange={handleInputChange}
                                name="vehicleJobPartDatePurchased"
                            />
                            <FormHelperText error>{partState.errors.vehicleJobPartDatePurchased}</FormHelperText>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={4}>
                            <SimpleField
                                isDisabled={state.readOnly}
                                inputLabel="Supplier Invoice Number"
                                name="vehicleJobPartWIPNumber"
                                placeholder="Invoice Number"
                                onChange={handleInputChange}
                                value={state.vehicleJobPartWIPNumber}
                                errors={partState.errors.vehicleJobPartWIPNumber}
                            />
                        </Grid>
                        <Grid item xs={8} sm={6} md={4} lg={4} component="label" alignItems="center">
                            <InputLabel shrink>Payment Method</InputLabel>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button
                                    style={{ fontSize: 12, height: '40px' }}
                                    variant={state.vehicleJobPartPaymentType === 'A' ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter('A')}
                                >
                                    Account
                                </Button>
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.vehicleJobPartPaymentType === 'C' ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter('C')}
                                >
                                    Credit Card
                                </Button>
                            </ButtonGroup>
                            <FormHelperText error>{partState.errors.vehicleJobPartPaymentType}</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleField
                                isDisabled={state.readOnly}
                                inputLabel="Purchased From"
                                name="vehicleJobPartSupplier"
                                placeholder="Purchased From"
                                onChange={handleInputChange}
                                value={state.vehicleJobPartSupplier}
                                errors={partState.errors.vehicleJobPartSupplier}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {ScreenWidth > 500 ? (
                    <Grid item xs={12} sm={4} lg={4}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {/* <InputLabel shrink error required>
                                Receipt (Primary Battery)
                            </InputLabel> */}

                                <Grid item xs={12} className="gridcontainer" style={{ height: '100%', position: 'relative', border: '1px dashed #5e87f8' }}>
                                    {state?.vehicleJobPartImage && (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: 0
                                            }}
                                        >
                                            <Tooltip title="Remove Receipt">
                                                <CloseIcon fontSize="small" onClick={clearImage} />
                                            </Tooltip>
                                        </span>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            height: '350px'
                                        }}
                                    >
                                        {state?.vehicleJobPartImage ? (
                                            <img
                                                src={state?.vehicleJobPartImage}
                                                alt={'Receipt'}
                                                style={{
                                                    objectFit: 'contain',
                                                    objectPosition: '50% 50%',
                                                    width: '100%',
                                                    height: '100%'
                                                    // border: 'solid 1px #CCC'
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div>
                                                    <Tooltip title="Upload Document">
                                                        <label>
                                                            <IconButton color="secondary" component="span" style={{ padding: '0px' }}>
                                                                <Backup style={{ fontSize: '60px' }} />
                                                                <input
                                                                    type="file"
                                                                    onChange={handleImage}
                                                                    // className="upload-btn-input-file"
                                                                    style={{ display: 'none' }}
                                                                    accept="image/*"
                                                                />
                                                            </IconButton>
                                                        </label>
                                                    </Tooltip>
                                                </div>
                                                <p>No Image Found</p>
                                            </div>
                                        )}
                                    </Grid>
                                    <span>{state?.primaryErrors?.vehicleJobPartImage}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                <Grid item xs={ScreenWidth > 500 ? 12 : 6} className="submit_btn">
                    <SecondaryButton disabled={state.readOnly} onClick={confirmAndSubmit}>
                        {selectedPurchaedPart.isLocallyUpdated || selectedPurchaedPart?.vehicleJobPartID ? 'Update' : 'Add'}
                    </SecondaryButton>
                </Grid>
                {ScreenWidth < 500 && (
                    <Grid item xs={6}>
                        <PrimaryButton disabled={state.readOnly} onClick={handleImageModal}>
                            receipt
                        </PrimaryButton>
                    </Grid>
                )}
                {/* {partState?.matchingPartNumber.length > 0 && ( */}
                <ClickAwayListener onClickAway={handlePopperClose}>
                    <Popper open={open} anchorEl={anchorEl} placement="bottom-start" className={classes.popper} disablePortal>
                        <Grid item xs={12}>
                            <TableContainer className="custom-scroll" style={{ maxHeight: '420px', borderRadius: '0px' }}>
                                <Table className={classes.table} style={{ minWidth: 550 }} aria-label="customized table" stickyHeader>
                                    <TableHead>
                                        <StyledTableRow>
                                            {/* <StyledTableCell> </StyledTableCell> */}
                                            <StyledTableCell>Part No.</StyledTableCell>
                                            <StyledTableCell>Description</StyledTableCell>
                                            <StyledTableCell width="20%">Cost Price</StyledTableCell>
                                            <StyledTableCell width="20%">Selling Price</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {partState?.matchingPartNumber.length > 0 ? (
                                            partState?.matchingPartNumber.map((row, i) => {
                                                return (
                                                    <StyledTableRow key={row.partID} onClick={() => patchSelectedRowWrapper(row)}>
                                                        {/* <StyledTableCell>
                                                            <MergeTypeIcon onClick={() => patchSelectedRowWrapper(row)} />
                                                        </StyledTableCell> */}
                                                        <StyledTableCell>{row.pn}</StyledTableCell>
                                                        <StyledTableCell>{row.pd}</StyledTableCell>
                                                        <StyledTableCell>{row.cp}</StyledTableCell>
                                                        <StyledTableCell>{row.sp}</StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })
                                        ) : (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={5} height={'100%'}>
                                                    <Alert severity="info">No Record Found</Alert>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {/* )} */}
                    </Popper>
                </ClickAwayListener>
            </Grid>
            {partState.showConfirmationModal ? (
                <DialogComp title="Confirmation" onClose={() => closeConfirmationModal(false)} maxWidth="sm">
                    <ConfirmationModal
                        message={`Are you sure to save part details without uploading receipt?`}
                        handleSubmit={() => addPartToLocally(selectedPurchaedPart?.vehicleJobPartID, selectedPurchaedPart.id)}
                    />
                </DialogComp>
            ) : null}
            {partState.showImageModal ? (
                <DialogComp title="Receipt" onClose={() => closeConfirmationModal(false)} maxWidth="sm" fullWidth>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {/* <InputLabel shrink error required>
                                Receipt (Primary Battery)
                            </InputLabel> */}

                            <Grid item xs={12} className="gridcontainer" style={{ height: '95%', position: 'relative', border: '1px dashed #5e87f8' }}>
                                {state?.vehicleJobPartImage && (
                                    <span
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: 0
                                        }}
                                    >
                                        <Tooltip title="Remove Receipt">
                                            <CloseIcon fontSize="small" onClick={clearImage} />
                                        </Tooltip>
                                    </span>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        height: '350px'
                                    }}
                                >
                                    {state?.vehicleJobPartImage ? (
                                        <img
                                            src={state?.vehicleJobPartImage}
                                            alt={'Receipt'}
                                            style={{
                                                objectFit: 'contain',
                                                objectPosition: '50% 50%',
                                                width: '100%',
                                                height: '100%'
                                                // border: 'solid 1px #CCC'
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <div>
                                                <Tooltip title="Upload Document">
                                                    <label>
                                                        <IconButton color="secondary" component="span" style={{ padding: '0px' }}>
                                                            <Backup style={{ fontSize: '60px' }} />
                                                            <input
                                                                type="file"
                                                                onChange={handleImage}
                                                                // className="upload-btn-input-file"
                                                                style={{ display: 'none' }}
                                                                accept="image/*"
                                                            />
                                                        </IconButton>
                                                    </label>
                                                </Tooltip>
                                            </div>
                                            <p>No Image Found</p>
                                        </div>
                                    )}
                                </Grid>
                                <span>{state?.primaryErrors?.vehicleJobPartImage}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
        </div>
    );
};
