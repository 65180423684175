import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    CustomChip,
    DatePicker,
    DateTimePicker,
    MandatoryField,
    SecondaryButton,
    SelectBox,
    SimpleField,
    SingleSelect,
    formatters
} from '../../../../Core/Controls';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { getUserDropdown } from '../../../../Core/Services/Admin/userService';
import { getVehicleScreenDropdowns } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import {
    postFleetContract,
    postHireFleetContract,
    postMaintenanceFleetContract,
    postVehicleContract_DeleteContract
} from '../../../../Core/Services/InternalUser/contractService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { getContracts, getContractsDropDown } from '../../../../Core/Services/InternalUser/Vehicle/contractVechicleService';
import '../../Controllers/controllerStyle.scss';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { useHistory } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
import { useLoader } from '../../../../Core/Hooks/useLoader';
import FileUploaderButton from '../../../../Core/Controls/GenericFileUploader';
import { getJobEnggById } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from '../../../../Core/Modal/ConfirmationModal';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import MasterContractHistory from './MasterContractHistory';
import HistoryIcon from '@material-ui/icons/History';

const YesNoRadio = (props) => {
    return (
        <Grid container>
            <Grid container item spacing={1} style={{ marginLeft: '2px' }}>
                <RadioGroup row aria-label={props.name} name={props.name} value={props.value} onChange={props.onChange}>
                    <FormControlLabel checked={props.value === true} value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={props.value === false} value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
        </Grid>
    );
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '4px',
        height: '40px'
    }
}))(TableCell);

const VehicleStyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 12,
        fontWeight: 700,
        padding: 5,
        backgroundColor: 'lightgrey'
    },
    body: {
        fontSize: 14,
        padding: 5
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    title: {
        color: '#f50057',
        marginLeft: 2,
        marginTop: 0,
        marginBottom: 0
    },
    cellValue: {
        fontSize: '0.800rem'
    },
    label: {
        position: 'fixed',
        top: 0
    }
});
const gridStyle = {
    title: {
        color: '#f50057'
    },
    header: {
        border: '1px solid rgba(0,0,0,0.2)'
    },
    tbHead: {
        padding: '5px 0px 6px 8px'
    },
    tbContent: {
        margin: '8px 0px 14px 0px;'
    }
};

const ContractEditScreen = (props) => {
    const classes = useStyles();
    const { showLoader, hideLoader } = useLoader();

    const contractID = props.match.params.contractID;
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const [tempOptions, setTempOptions] = useState({
        contractCustomerID: null,
        contractSummerServiceAreaID: null
    });
    const [state, setState] = useState({
        errors: {},
        depotsList: [],
        customersList: [],
        serviceAgentsList: [],
        engineersList: [],
        vehicleTypeList: [],
        spreaderTypeList: [],
        contractDurationTypes: [],
        contractTypes: [],
        agreementTypes: [],
        maintenanceProviders: [],
        contractStatus: [],
        contractTypeID: '',
        contractEngineerUserID: '',
        contractReference: '',
        contractNominalCode: '',
        contractWinterTerm: '',
        contractTerm: '',
        contractStartDate: '',
        contractEndDate: '',
        contractWinterStartDate: '',
        contractPurchasePrice: '',
        contractCustomerID: '',
        contractDepotID: '',
        contractServiceAreaID: '',
        contractDescription: '',
        contractInvoiceCustomerID: '',
        contractPurchaseOrder: '',
        contractInvoiceAddress: '',
        contractTitle: '',
        vehicleOnSiteDate: '',
        selectedVehicleList: [],
        linkedVehiclesList: [],
        contractHistoryList: [],
        contractInvoiceAllYear: false,
        contractPayAsYouGo: false,
        contractTyreSupplier: '',
        contractDurationTypeID: '',
        contractStatusID: '',
        apiContractStatusID: '',
        contractMaintenanceProviderId: '',
        contractSummerServiceAreaID: '',
        fileCount: 0,
        contractEngineerUserID: null,
        deLinkContractVehicle: [],
        contractInvoiceFrequency: null
        // vehiclesIDs: []
    });
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [vehicleBtnStatus, setVehicleBtnStatus] = useState('A');

    const handleChange = (statusID) => {
        setVehicleBtnStatus(statusID);
        if (statusID === 'C' || statusID === 'X') {
            setState((st) => ({ ...st, selectedVehicleList: st.contractHistoryList || [] }));
        } else {
            setState((st) => ({ ...st, selectedVehicleList: st.linkedVehiclesList || [] }));
        }
    };

    const updateStateWithDropdownData = (result) => {
        const newState = {
            depotsList: result.depots || [],
            customersList: result.customers || [],
            serviceAgentsList: result.serviceAgents || [],
            // engineersList: result.technician || [],
            vehicleTypeList: result.vehicleType || [],
            spreaderTypeList: result.spreaderType || [],
            contractTypes:
                result.contractTypes.map((m) => ({
                    id: m.contractTypeID,
                    name: m.contractTypeDescription
                })) || [],
            maintenanceProviders:
                result.maintenanceProviders.map((m) => ({
                    id: m.maintenanceProviderID,
                    name: m.maintenanceProviderName
                })) || [],
            contractStatus:
                result.contractStatus.map((m) => ({
                    id: m.contractStatusID,
                    name: m.contractStatusDescription
                })) || []
        };

        if (!contractID) {
            newState.contractTypeID = newState.contractTypes[0]?.id || '';
            newState.contractStatusID = newState.contractStatus[0]?.id || '';
        }

        setState((st) => ({ ...st, ...newState }));
    };

    const updateStateWithContractData = (data, linkedVehicles, contractHistory) => {
        setState((st) => ({
            ...st,
            selectedVehicleList: data.contractStatusID == 'A' ? linkedVehicles : contractHistory || [],
            linkedVehiclesList: linkedVehicles || [],
            contractHistoryList: contractHistory || [],
            contractReference: data.contractReference,
            contractCustomerID: data.contractCustomerID,
            contractServiceAreaID: data.contractServiceAreaID,
            contractSummerServiceAreaID: data.contractSummerServiceAreaID,
            contractEngineerUserID: data.contractEngineerUserID,
            contractStartDate: data.contractStartDate,
            contractEndDate: data.contractEndDate,
            contractExtendedDate: data.contractExtendedDate || null,
            contractWinterStartDate: data.contractWinterStartDate || null,
            contractTerm: data.contractTerm || null,
            contractWinterTerm: data.contractWinterTerm || null,
            contractCollectionChargeDate: data.contractCollectionChargeDate || null,
            contractSparesRequired: data.contractSparesRequired || null,
            contractTypeID: data.contractTypeID || null,
            contractStatusID: data.contractStatusID || null,
            apiContractStatusID: data.contractStatusID || null,
            contractSalesRep: data.contractSalesRep || null,
            contractFramework: data.contractFramework || null,
            contractNumberOfVehicles: data.contractNumberOfVehicles || null,
            contractInvoiceFrequency: data.contractInvoiceFrequency || null,
            contractPurchaseOrder: data.contractPurchaseOrder || null,
            contractDescription: data.contractDescription,
            contractTempStartDate: data.contractStartDate,
            contractSpreaderType: data.contractSpreaderType || '',
            contractAnnualPriceIncrease: data.contractAnnualPriceIncrease || '',
            contractIncreaseAmount: data.contractIncreaseAmount || '',
            contractIncreaseDate: data.contractIncreaseDate || '',
            contractPayAsYouGo: data.contractPayAsYouGo || '',
            fileCount: data.fileCount || 0,
            deLinkContractVehicle: []
        }));
        setVehicleBtnStatus(data.contractStatusID || 'A');
    };

    const fetchData = async () => {
        showLoader();
        try {
            const dropdownResult = await getContractsDropDown();
            if (dropdownResult.success) {
                updateStateWithDropdownData(dropdownResult.data);
            }

            if (contractID) {
                const contractResult = await getContracts(contractID);
                if (contractResult.success) {
                    const contractData = contractResult.data.details[0];
                    updateStateWithContractData(contractData, contractResult.data.linkedVehicles, contractResult.data.contractHistory);
                }
            }
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        fetchData();
    }, [contractID]);

    useEffect(async () => {
        if (state.contractServiceAreaID) {
            let res = await getJobEnggById(state.contractServiceAreaID);
            if (res.success) {
                setState((d) => ({ ...d, engineersList: res.data.list }));
            }
        }
    }, [state.contractServiceAreaID]);

    useEffect(() => {
        let contractCustomer = '';
        let contractSummrService = '';
        if (state.contractCustomerID && state.customersList.length > 0) {
            contractCustomer = state.customersList.find(({ id }) => id === state.contractCustomerID) || null;
        }
        if (state.contractSummerServiceAreaID && state.serviceAgentsList.length > 0 && state.contractTypeID == 1) {
            contractSummrService = state.serviceAgentsList.find(({ id }) => id === state.contractSummerServiceAreaID) || null;
        }
        setTempOptions((st) => ({
            ...st,
            contractCustomerID: contractCustomer,
            contractSummerServiceAreaID: contractSummrService
        }));
    }, [state.contractCustomerID, state.customersList, state.contractSummerServiceAreaID, state.serviceAgentsList]);

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        }
        // else if (name == 'contractStartDate') {
        //     setState((st) => ({ ...st, [name]: value }));
        // }
        else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const validations = () => {
        let formIsValid = true;
        let errors = {};
        if (!state.contractReference) {
            errors.contractReference = 'This field is required';
            formIsValid = false;
        }
        if (!state.contractTypeID) {
            errors.contractTypeID = 'This field is required';
            formIsValid = false;
        }
        // if (!state.contractPurchaseOrder && state.contractTypeID == 1) {
        //     errors.contractPurchaseOrder = 'This field is required';
        //     formIsValid = false;
        // }
        if (!tempOptions.contractCustomerID?.id) {
            errors.contractCustomerID = 'This field is required';
            formIsValid = false;
        }
        if (!tempOptions.contractSummerServiceAreaID?.id && state.contractTypeID == 1) {
            errors.contractSummerServiceAreaID = 'This field is required';
            formIsValid = false;
        }
        if (!state.contractServiceAreaID) {
            errors.contractServiceAreaID = 'This field is required';
            formIsValid = false;
        }
        // if (!state.contractStartDate) {
        //     errors.contractStartDate = 'This field is required';
        //     formIsValid = false;
        // }
        // if (!state.contractEndDate) {
        //     errors.contractEndDate = 'This field is required';
        //     formIsValid = false;
        // }
        if (!state.contractWinterStartDate && state.contractTypeID == 1) {
            errors.contractWinterStartDate = 'This field is required';
            formIsValid = false;
        }
        if (!state.contractTerm) {
            errors.contractTerm = 'This field is required';
            formIsValid = false;
        }
        if (!state.contractWinterTerm && state.contractTypeID == 1) {
            errors.contractWinterTerm = 'This field is required';
            formIsValid = false;
        }
        console.log(errors);
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            setBtnLoader(true);
            let data = {
                contractID: contractID || null,
                contractReference: state.contractReference,
                // contractTitle: state.contractTitle,
                contractCustomerID: tempOptions.contractCustomerID?.id,
                contractSummerServiceAreaID: tempOptions.contractSummerServiceAreaID?.id,
                // contractDepotID: state.contractDepotID,
                contractServiceAreaID: state.contractServiceAreaID,
                contractEngineerUserID: state.contractEngineerUserID,
                contractStartDate: state.contractStartDate,
                contractEndDate: state.contractEndDate,
                contractExtendedDate: state.contractExtendedDate || null,
                contractWinterStartDate: state.contractWinterStartDate || null,
                contractTerm: state.contractTerm || null,
                contractWinterTerm: state.contractWinterTerm || null,
                contractCollectionChargeDate: state.contractCollectionChargeDate || null,
                contractSparesRequired: state.contractSparesRequired || null,
                contractTypeID: state.contractTypeID || null,
                contractStatusID: state.contractStatusID || null,
                contractSalesRep: state.contractSalesRep || null,
                contractFramework: state.contractFramework || null,
                contractNumberOfVehicles: state.contractNumberOfVehicles || null,
                contractInvoiceFrequency: state.contractInvoiceFrequency || null,
                contractPurchaseOrder: state.contractPurchaseOrder || null,
                contractDescription: state.contractDescription,
                contractSpreaderType: state.contractSpreaderType || '',
                contractAnnualPriceIncrease: state.contractAnnualPriceIncrease || '',
                contractIncreaseAmount: state.contractIncreaseAmount || '',
                contractIncreaseDate: state.contractIncreaseDate || '',
                contractPayAsYouGo: state.contractPayAsYouGo || null
            };

            let res = await postFleetContract(data);
            if (res.success) {
                showToastSuccess(res?.message);
                onClose(false);
                setBtnLoader(false);
            } else {
                showToastError(res?.message);
                setBtnLoader(false);
            }
            setBtnLoader(false);
        }
    };
    const history = useHistory();
    const onClose = (res) => {
        if (res) {
            fetchData();
        } else {
            history.push({
                pathname: `/contractList`
            });
        }
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: vehicleBtnStatus !== 'A' ? `?redirectLabel=Contract History&contractHistoryID=${val.contractHistoryID}` : `?redirectLabel=Contract`
        });
    };
    const handleLinkVehiclesClick = (val) => {
        if (state.contractTypeID == 1) {
            history.push({
                pathname: `/Contracts_Hire_Fleet`,
                search: `isFreeStock=1&contractID=${contractID}`
            });
        } else if (state.contractTypeID == 2) {
            history.push({
                pathname: `/Contracts_Maintenance`,
                search: `isFreeStock=1&contractID=${contractID}`
            });
        } else {
        }
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({
            ...st,
            [nm]: val
        }));
    };

    const deLinkContractVehicleHandler = async () => {
        let data = {
            vehiclesIDs: state.deLinkContractVehicle.map((q) => q.vehicleID)
        };
        let res = await postVehicleContract_DeleteContract(data);
        if (res.success) {
            showToastSuccess(res?.message);
            closeConfirmationModal();
            fetchData();
        } else {
            showToastError(res?.message);
        }
    };

    const handleClick = (e, row) => {
        const { checked } = e.target;

        setState((st) => {
            let deLinkContractVehicle = [...st.deLinkContractVehicle];
            let selectedVehicleList = [...st.selectedVehicleList];

            const vehicleIndex = deLinkContractVehicle.findIndex((q) => row.vehicleID === q.vehicleID);
            const selectedVehicleIndex = selectedVehicleList.findIndex((q) => row.vehicleID === q.vehicleID);

            // Toggle isSelected in selectedVehicleList array
            if (selectedVehicleIndex !== -1) {
                selectedVehicleList[selectedVehicleIndex].isSelected = checked;
            }

            // Update deLinkContractVehicle array based on checkbox state
            if (checked && vehicleIndex === -1) {
                deLinkContractVehicle.push(row);
            } else if (!checked && vehicleIndex !== -1) {
                deLinkContractVehicle.splice(vehicleIndex, 1);
            }

            return { ...st, deLinkContractVehicle, selectedVehicleList };
        });
    };

    const closeConfirmationModal = (res) => {
        setState((st) => ({
            ...st,
            showConfirm: false,
            showHistory: false
        }));
    };
    const confirmationModalHandler = (res) => {
        // if (validations()) {
        setState((st) => ({
            ...st,
            showConfirm: true
        }));
        // }
    };

    const setShowHistory = () => {
        setState((st) => ({ ...st, showHistory: true }));
    };
    return (
        <div style={{ overflow: 'hidden', padding: '10px 20px' }} className="controller-screen">
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={contractID ? 7 : 12} xl={contractID ? 6 : 12}>
                    <Grid container style={{ alignItems: 'center', padding: '0px 6px' }}>
                        <Grid
                            xs={12}
                            item
                            style={{ alignItems: 'center', padding: '5px 0px 6px 8px', border: '1px solid rgba(0,0,0,0.2)', marginBottom: '5px' }}
                        >
                            <Grid container justify="space-between">
                                <Grid item xs={6} md={10} lg={10}>
                                    <Typography variant="h6" color="secondary">
                                        Contract Details
                                    </Typography>
                                </Grid>
                                <Grid container item xs={3} md={2} lg={2} spacing={2}>
                                    <Grid item>
                                        <HistoryIcon onClick={setShowHistory} style={{ cursor: 'pointer' }} />
                                    </Grid>
                                    <Grid item>
                                        <FileUploaderButton recordId={contractID} fileUploaderConfigName="ContractScreen" fileCount={state.fileCount} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {/* <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                                    <MandatoryField
                                        inputLabel="Contract Reference"
                                        disabled={state.disabled}
                                        placeholder="Contract Reference"
                                        value={state.contractReference}
                                        onChange={inputChange}
                                        name="contractReference"
                                        errors={state.errors.contractReference}
                                        autoFocus={true}
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink required error>
                                        Contract Reference*
                                    </FormHelperText>
                                    <SimpleField onChange={inputChange} name="contractReference" value={state.contractReference} autoFocus={true} />
                                    <FormHelperText error>{state.errors.contractReference}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink error required>
                                        Contract type*
                                    </FormHelperText>
                                    <SelectBox onChange={inputChange} List={state.contractTypes || []} name="contractTypeID" value={state.contractTypeID} />
                                    <FormHelperText error>{state.errors.contractTypeID}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText
                                        shrink
                                        // required={state.contractTypeID == 1} error={state.contractTypeID == 1}
                                    >
                                        Purchase Order
                                    </FormHelperText>
                                    <SimpleField onChange={inputChange} name="contractPurchaseOrder" value={state.contractPurchaseOrder} />
                                    <FormHelperText error>{state.errors.contractPurchaseOrder}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink>Contract Status</FormHelperText>
                                    <SelectBox
                                        onChange={inputChange}
                                        List={state.contractStatus || []}
                                        name="contractStatusID"
                                        value={state.contractStatusID}
                                    />
                                </Grid>

                                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink required error>
                                        Customer
                                    </FormHelperText>
                                    <SelectBox
                                        disabled={contractID && state.contractCustomerID}
                                        name="contractCustomerID"
                                        value={state.contractCustomerID}
                                        onChange={inputChange}
                                        List={state.customersList || []}
                                    />
                                    <FormHelperText error>{state.errors.contractCustomerID}</FormHelperText>
                                </Grid> */}

                                <Grid item xs={12} sm={6} md={4} lg={6} className="mandatory-fields">
                                    <FormHelperText required shrink error>
                                        Customer*
                                    </FormHelperText>
                                    <SingleSelect
                                        name="contractCustomerID"
                                        options={state.customersList}
                                        value={tempOptions.contractCustomerID}
                                        onChange={singleSelectChange('contractCustomerID')}
                                        disabled={state.contractCustomerID}
                                    />
                                    <FormHelperText error>{state.errors.contractCustomerID}</FormHelperText>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormHelperText shrink required error>
                                        Service Area*
                                    </FormHelperText>
                                    <SelectBox
                                        name="contractServiceAreaID"
                                        value={state.contractServiceAreaID}
                                        onChange={inputChange}
                                        List={state.serviceAgentsList || []}
                                    />
                                    <FormHelperText error>{state.errors.contractServiceAreaID}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormHelperText shrink>Engineer</FormHelperText>
                                    <SelectBox
                                        name="contractEngineerUserID"
                                        value={state.contractEngineerUserID}
                                        onChange={inputChange}
                                        List={state.engineersList || []}
                                    />
                                    <FormHelperText error>{state.errors.contractEngineerUserID}</FormHelperText>
                                </Grid>
                                {state.contractTypeID == 1 && (
                                    <Grid item xs={12} sm={6}>
                                        <FormHelperText shrink required error>
                                            Summer Service Area*
                                        </FormHelperText>
                                        <SingleSelect
                                            name="contractSummerServiceAreaID"
                                            value={tempOptions.contractSummerServiceAreaID}
                                            onChange={singleSelectChange('contractSummerServiceAreaID')}
                                            options={state.serviceAgentsList}
                                        />
                                        <FormHelperText error>{state.errors.contractSummerServiceAreaID}</FormHelperText>
                                    </Grid>
                                )}

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink>{state.contractTypeID == 1 ? 'Contract Commencement' : 'Start date'}</FormHelperText>
                                    <DatePicker
                                        placeholder="Start Date"
                                        name="contractStartDate"
                                        inputProps={{
                                            min: moment(state.contractTempStartDate).format('YYYY-MM-DD')
                                        }}
                                        disabled={moment(state.contractTempStartDate).isBefore(moment().format('YYYY-MM-DD'))}
                                        value={state.contractStartDate && moment(state.contractStartDate).format('YYYY-MM-DD')}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.contractStartDate}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink>{state.contractTypeID == 1 ? 'Contract Termination Date' : 'End date'}</FormHelperText>

                                    <DatePicker
                                        placeholder="End Date"
                                        name="contractEndDate"
                                        value={state.contractEndDate && moment(state.contractEndDate).format('YYYY-MM-DD')}
                                        onChange={inputChange}
                                    />
                                    {/* <FormHelperText error>{state.errors.contractEndDate}</FormHelperText> */}
                                </Grid>

                                {state.contractTypeID == 1 && (
                                    <>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink error required>
                                                Contract Winter Start Date*
                                            </FormHelperText>
                                            <DatePicker
                                                value={state.contractWinterStartDate && moment(state.contractWinterStartDate).format('YYYY-MM-DD')}
                                                onChange={inputChange}
                                                name="contractWinterStartDate"
                                            />
                                            <FormHelperText error>{state.errors.contractWinterStartDate}</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink>Extended Date</FormHelperText>
                                            <DatePicker
                                                value={state.contractExtendedDate && moment(state.contractExtendedDate).format('YYYY-MM-DD')}
                                                onChange={inputChange}
                                                name="contractExtendedDate"
                                            />
                                        </Grid>{' '}
                                    </>
                                )}
                                {/* <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                                    <MandatoryField
                                        FormHelperText="Contract Term (Years)"
                                        type="number"
                                        onChange={inputChange}
                                        name="contractTerm"
                                        value={state.contractTerm}
                                        errors={state.errors.contractTerm}
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink required error>
                                        Contract Term (Years)*
                                    </FormHelperText>
                                    <SimpleField type="number" onChange={inputChange} name="contractTerm" value={state.contractTerm} autoFocus={true} />
                                    <FormHelperText error>{state.errors.contractTerm}</FormHelperText>
                                </Grid>
                                {state.contractTypeID == 1 && (
                                    <>
                                        {/* <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                                            <MandatoryField
                                                FormHelperText="Winter Term (Weeks)"
                                                type="number"
                                                onChange={inputChange}
                                                name="contractWinterTerm"
                                                value={state.contractWinterTerm}
                                                errors={state.errors.contractWinterTerm}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink required error>
                                                Winter Term (Weeks)*
                                            </FormHelperText>
                                            <SimpleField
                                                type="number"
                                                onChange={inputChange}
                                                name="contractWinterTerm"
                                                value={state.contractWinterTerm}
                                                autoFocus={true}
                                            />
                                            <FormHelperText error>{state.errors.contractWinterTerm}</FormHelperText>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink>No.Of Vehicles</FormHelperText>
                                    <SimpleField onChange={inputChange} name="contractNumberOfVehicles" value={state.contractNumberOfVehicles} />
                                </Grid>
                                {state.contractTypeID == 1 && (
                                    <>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink>Spares Required</FormHelperText>
                                            <SimpleField
                                                typr="number"
                                                onChange={inputChange}
                                                name="contractSparesRequired"
                                                value={state.contractSparesRequired}
                                            />
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink>Sales Rep</FormHelperText>
                                    <SimpleField onChange={inputChange} name="contractSalesRep" value={state.contractSalesRep} />
                                </Grid>

                                {state.contractTypeID == 1 && (
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FormHelperText shrink>Collection Charge Date</FormHelperText>
                                        <DatePicker
                                            value={state.contractCollectionChargeDate && moment(state.contractCollectionChargeDate).format('YYYY-MM-DD')}
                                            onChange={inputChange}
                                            name="contractCollectionChargeDate"
                                        />
                                    </Grid>
                                )}
                                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink>Invoice Frequency</FormHelperText>
                                            <SimpleField
                                                onChange={inputChange}
                                                name="contractInvoiceFrequency"
                                                value={state.contractInvoiceFrequency}
                                                inputProps={{ maxLength: 1 }}
                                            />
                                        </Grid> */}
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormHelperText shrink>Invoice Frequency</FormHelperText>
                                    <SelectBox
                                        name="contractInvoiceFrequency"
                                        value={state.contractInvoiceFrequency}
                                        onChange={inputChange}
                                        List={[
                                            {
                                                name: 'Daily',
                                                id: 'd'
                                            },
                                            {
                                                name: 'Weekly',
                                                id: 'w'
                                            },
                                            {
                                                name: '4 Weeks',
                                                id: '4'
                                            },
                                            {
                                                name: '52 Weeks',
                                                id: '5'
                                            },
                                            {
                                                name: 'Monthly',
                                                id: 'm'
                                            },
                                            {
                                                name: 'Yearly',
                                                id: 'y'
                                            }
                                        ]}
                                    />
                                    <FormHelperText error>{state.errors.contractInvoiceFrequency}</FormHelperText>
                                </Grid>

                                {state.contractTypeID == 1 && (
                                    <>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink>Framework</FormHelperText>
                                            <SimpleField onChange={inputChange} name="contractFramework" value={state.contractFramework} />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink>Spreader Type</FormHelperText>
                                            <SelectBox
                                                name="contractSpreaderType"
                                                value={state.contractSpreaderType}
                                                onChange={inputChange}
                                                List={state.spreaderTypeList || []}
                                            />
                                            <FormHelperText error>{state.errors.contractSpreaderType}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            {/* <SimpleField onChange={inputChange} name="contractInvoiceAllYear" value={state.contractInvoiceAllYear} /> */}
                                            <Grid container>
                                                <Grid item xs={8}>
                                                    <FormHelperText shrink>Annual Price Increase</FormHelperText>
                                                    <YesNoRadio
                                                        name="contractAnnualPriceIncrease"
                                                        value={state.contractAnnualPriceIncrease || false}
                                                        onChange={inputChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormHelperText shrink>Increase %</FormHelperText>
                                                    <SimpleField
                                                        type="number"
                                                        onChange={inputChange}
                                                        name="contractIncreaseAmount"
                                                        value={state.contractIncreaseAmount}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink>Increase Date</FormHelperText>
                                            <DatePicker
                                                value={state.contractIncreaseDate && moment(state.contractIncreaseDate).format('YYYY-MM-DD')}
                                                onChange={inputChange}
                                                name="contractIncreaseDate"
                                            />
                                        </Grid>
                                    </>
                                )}
                                {state.contractTypeID == 2 && (
                                    <>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <FormHelperText shrink>Pay As You Go</FormHelperText>
                                            <YesNoRadio name="contractPayAsYouGo" value={state.contractPayAsYouGo || false} onChange={inputChange} />
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={12}>
                                    <SimpleField
                                        multiline
                                        rows={7}
                                        FormHelperText="Notes"
                                        disabled={state.disabled}
                                        placeholder="Notes"
                                        value={state.contractDescription}
                                        onChange={inputChange}
                                        name="contractDescription"
                                        // errors={state.errors.contractDescription}
                                    />
                                </Grid>

                                <br />
                                <Grid container spacing={1}>
                                    <Grid item xs={props.vehicleContractID ? 12 : 6} className="submit_btn">
                                        <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit} disabled={!checkhaveAccess?.CanUpdateContracts}>
                                            {props.vehicleContractID ? 'Update' : 'Submit'}
                                        </SecondaryButton>
                                    </Grid>
                                    <Grid item xs={6} className="submit_btn" style={{ display: props.vehicleContractID ? 'none' : 'block' }}>
                                        <SecondaryButton onClick={() => onClose(false)} forceEnabled={true}>
                                            Cancel
                                        </SecondaryButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {contractID && (
                    <Grid item xs={12} md={12} lg={5} xl={6} style={{ height: '100%' }}>
                        <Grid
                            container
                            style={{
                                borderBottom: '1px solid rgba(0,0,0,0.2)',
                                padding: '5px 0px 6px 8px'
                            }}
                            justify="space-between"
                        >
                            <Typography variant="h6" color="secondary">
                                Linked Vehicles
                                {vehicleBtnStatus === 'A'
                                    ? ` (${state.selectedVehicleList.length || 0} of ${
                                          (+state.contractNumberOfVehicles || 0) + (+state.contractSparesRequired || 0)
                                      })`
                                    : ''}{' '}
                                &nbsp;
                                {state.apiContractStatusID == 'A' && (
                                    <ChipWithoutLable
                                        className="btn-m"
                                        icon={LinkIcon}
                                        toolTipTitle={'Link Vehicles'}
                                        // label="Add Defect"
                                        onClick={() => handleLinkVehiclesClick()}
                                    />
                                )}
                            </Typography>
                            <Grid component="label" item alignItems="center">
                                <ButtonGroup color="primary" aria-label="outlined primary button group" style={{ paddingRight: '5px' }}>
                                    <Button
                                        style={{ fontSize: 12 }}
                                        variant={vehicleBtnStatus === 'A' ? 'contained' : 'outlined'}
                                        onClick={() => handleChange('A')}
                                        disabled={state.apiContractStatusID !== 'A'}
                                    >
                                        Active
                                    </Button>
                                    <Button
                                        style={{ fontSize: 12 }}
                                        variant={vehicleBtnStatus === 'C' || vehicleBtnStatus === 'X' ? 'contained' : 'outlined'}
                                        onClick={() => handleChange('C')}
                                    >
                                        History
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <TableContainer
                            style={{
                                border: '1px solid rgba(0,0,0,0.2)',
                                // height: '95%'
                                height: 'calc(100vh - 155px)'
                            }}
                            className="custom-scroll"
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <StyledTableRow>
                                        <VehicleStyledTableCell width={20}>
                                            <Grid container style={{ marginLeft: '5px' }}>
                                                {state.deLinkContractVehicle.length > 0 && (
                                                    <ChipWithoutLable
                                                        icon={DeleteIcon}
                                                        onClick={confirmationModalHandler}
                                                        style={{ fontWeight: 'bold' }}
                                                        toolTipTitle={'De-Link Vehicle'}
                                                    />
                                                )}
                                            </Grid>
                                        </VehicleStyledTableCell>
                                        <VehicleStyledTableCell>Reg Number</VehicleStyledTableCell>
                                        <VehicleStyledTableCell>Depot</VehicleStyledTableCell>
                                        <VehicleStyledTableCell>Engineer</VehicleStyledTableCell>
                                        {state.contractTypeID == 1 && <VehicleStyledTableCell>Status</VehicleStyledTableCell>}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {state.selectedVehicleList.map((m) => {
                                        return (
                                            <StyledTableRow>
                                                <VehicleStyledTableCell>
                                                    {m.showDeleteBtn == 'Y' && (
                                                        <Checkbox
                                                            name={'isSelected'}
                                                            onChange={(e) => handleClick(e, m)}
                                                            checked={m.isSelected ? true : false}
                                                            disabled={m.showDeleteBtn == 'Y' ? false : true}
                                                        />
                                                    )}
                                                </VehicleStyledTableCell>
                                                <VehicleStyledTableCell onClick={() => handleEditRegClick(m)} style={{ cursor: 'pointer', fontWeight: 600 }}>
                                                    {m.vehicleRegNumber}
                                                </VehicleStyledTableCell>
                                                <VehicleStyledTableCell>{m.depot}</VehicleStyledTableCell>
                                                <VehicleStyledTableCell>{m.agent}</VehicleStyledTableCell>
                                                {state.contractTypeID == 1 && <VehicleStyledTableCell>{m.status}</VehicleStyledTableCell>}
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}

                {/* <Grid item xs={12}>
                    <SecondaryButton
                        // onClick={handleSubmit}
                        className="submit_btn"
                        isBtnLoader={btnLoader}
                        disabled={true}
                    >
                        Submit
                    </SecondaryButton>
                </Grid> */}
            </Grid>
            {state.showHistory ? (
                <DialogComp title={`History`} onClose={() => closeConfirmationModal(false)} maxWidth="sm" fullWidth>
                    <MasterContractHistory contractID={contractID} />
                </DialogComp>
            ) : null}
            {state.showConfirm ? (
                <DialogComp title={`Confirmation`} onClose={() => closeConfirmationModal(false)} maxWidth="md" fullWidth>
                    <ConfirmationModal
                        textAlign="center"
                        message={`Are you sure you want to remove the vehicles below from contract?`}
                        handleSubmit={() => deLinkContractVehicleHandler(false)}
                        handleCancel={() => closeConfirmationModal(false)}
                    />
                    <TableContainer
                        style={{
                            border: '1px solid rgba(0,0,0,0.2)',
                            // height: '95%'
                            height: '300px'
                        }}
                        className="custom-scroll"
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <StyledTableRow>
                                    <VehicleStyledTableCell>Reg Number</VehicleStyledTableCell>
                                    <VehicleStyledTableCell>Depot</VehicleStyledTableCell>
                                    <VehicleStyledTableCell>Engineer</VehicleStyledTableCell>
                                    {state.contractTypeID == 1 && <VehicleStyledTableCell>Status</VehicleStyledTableCell>}
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {state.deLinkContractVehicle.map((m) => {
                                    return (
                                        <StyledTableRow>
                                            <VehicleStyledTableCell>{m.vehicleRegNumber}</VehicleStyledTableCell>
                                            <VehicleStyledTableCell>{m.depot}</VehicleStyledTableCell>
                                            <VehicleStyledTableCell>{m.agent}</VehicleStyledTableCell>
                                            {state.contractTypeID == 1 && <VehicleStyledTableCell>{m.status}</VehicleStyledTableCell>}
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogComp>
            ) : null}
        </div>
    );
};

export default ContractEditScreen;
