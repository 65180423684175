import React, { useMemo, useState } from 'react';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { EditButton, formatters } from '../../../../Core/Controls';
import ReactDOM from 'react-dom';
import { Grid } from '@material-ui/core';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
import LinkIcon from '@material-ui/icons/Link';
import { Link, useHistory } from 'react-router-dom';
import CompleteScheduleModal from '../../Schedule/completeSchedule';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import ContractSetDeliveryDateModal from './statusModals/ContractSetDeliveryDateModal';
import ContractSetCollectedModal from './statusModals/ContractSetCollectedModal';
import ContractSetOnsiteDateModal from './statusModals/ContractSetOnsiteDateModal';
import ContractSetOffHireModal from './statusModals/ContractSetOffHireModal';
import ContractSetVehiclePickedModal from './statusModals/ContractSetVehiclePickedModal';
import CompleteScheduleModalForContract from './statusModals/completeSchedule';
import ContractSetAwaitingFactoryInspection from './statusModals/ContractSetAwaitingFactoryInspection';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import ContractSetPauseCompleted from './statusModals/ContractSetPausedComplete';
import ContractSetResume from './statusModals/ContractSetResumeContract';
import SelectedVehicleTableComp from './statusModals/SelectedVehicleTableComp';
var $ = window.$;
const baseColumns = [
    // {
    //     key: 'action',
    //     name: 'Action',
    //     width: 80
    //     // addFileUploaderIcon: true
    // },
    {
        key: 'reg',
        name: 'Reg Number',
        width: 100
        // events: {
        //     click: function (e) {
        //         props.history.push(`/controller/editVehicle/${e.data.record.vehicleID}`);
        //     }
        // }
    },
    { key: 'cref', name: 'Ref', width: 100 },
    { key: 'vehicleType', name: 'Vehicle Type', width: 90 },
    { key: 'cust', name: 'Customer', width: 200 },
    { key: 'depot', name: 'Depot', width: 200 },
    {
        key: 'startDate',
        name: 'Start Date',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    { key: 'osd', name: 'Onsite Date', formatter: formatters.Date_DDMMYYYY, width: 100 },
    { key: 'endDate', name: 'End', formatter: formatters.Date_DDMMYYYY, width: 100 },
    { key: 'sa', name: 'Service Area', width: 130 },
    // { key: 'engineer', name: 'Engineer', width: 130 },
    // { key: 'purchaseOrder', name: 'Purchase Order', width: 200 },
    { key: 'rate', name: 'Rate', formatter: formatters.NumberWithPound, width: 80, align: 'right' }
    // { key: 'status', name: 'Status', width: 190 }
];

const ContractStatusScreen = (props) => {
    let history = useHistory();
    const [openDialog, setOpenDialog] = useState({});
    const [state, setState] = useState({
        selectedRowData: {},
        checkedVehicleList: [],
        selectedRecords: []
    });

    // const renderEdit = (ev, row) => {
    //     return (
    //         <div
    //             style={{
    //                 textAlign: 'center'
    //             }}
    //         >
    //             <EditButton
    //                 toolTipTitle="Edit Schedule Events"
    //                 onClick={() => {
    //                     setState((st) => ({
    //                         ...st,
    //                         open: true,
    //                         selectedRowData: row,
    //                         isAdd: false
    //                     }));
    //                 }}
    //             />
    //         </div>
    //     );
    // };

    const getVal = async (gridRef, recList) => {
        let statusNextModal = '';
        var val = [];
        $(`${gridRef} tbody :checkbox:checked`).each(function (i) {
            val[i] = $(this).val();
        });

        let newIDList = [];
        let selectedRecords = [];
        val.forEach((m, i) => {
            let getValues = m - 1;
            newIDList.push(recList[getValues].contractVehicleID);
            selectedRecords.push(recList[getValues]);
        });

        if (+props.contractStatusID == 2) statusNextModal = 'setDeliveryDateModal';
        if (+props.contractStatusID == 3) statusNextModal = 'setCollectedModal';
        if (+props.contractStatusID == 4) statusNextModal = 'setOnsiteDateModal';
        if (+props.contractStatusID == 6) statusNextModal = 'setOffHireModal';
        if (+props.contractStatusID == 8) statusNextModal = 'setVehiclePickedModal';
        if (+props.contractStatusID == 9) statusNextModal = 'setAwaitingFactoryInspection';
        if (+props.contractStatusID == 10) statusNextModal = 'setPauseCompleted';
        if (+props.contractStatusID == 11) statusNextModal = 'setResumeContract';

        setOpenDialog({ [statusNextModal]: true });
        setState((st) => ({
            ...st,
            checkedVehicleList: newIDList,
            selectedRecords: selectedRecords
        }));
    };

    const mailButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <ChipWithoutLable
                    icon={LinkIcon}
                    onClick={() => getVal(gridRef, valueList)}
                    style={{ fontWeight: 'bold' }}
                    toolTipTitle={
                        props.contractStatusID == 2
                            ? 'Set delivery arranged'
                            : props.contractStatusID == 3
                            ? 'Set Collected'
                            : props.contractStatusID == 4
                            ? 'Set On Site Date'
                            : props.contractStatusID == 6
                            ? 'Set Off Hire Date'
                            : props.contractStatusID == 9
                            ? 'Set Vehicle Arrived'
                            : 'Link Contract'
                    }
                />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.contractVehicleID}`,
            search: `?redirectLabel=Contract`
        });
    };

    const editActionBtn = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <CustomIconButton
                    icon={CheckCircleIcon}
                    color="primary"
                    onClick={() => {
                        checkButton(row, 'complete');
                    }}
                    toolTipTitle="Complete Schedule"
                />
            </div>
        );
    };

    const renderRegNo = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                onClick={() => handleEditRegClick(row, 'showCustomerModal')}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold', paddingLeft: '5px' }}
            >
                {row.reg}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];

        if (
            props.contractStatusID == 1 ||
            props.contractStatusID == 5 ||
            props.contractStatusID == 7
            // || props.contractStatusID == 10
        ) {
            tempCols.unshift({
                key: 'action',
                name: 'Action',
                width: 60,
                formatter: editActionBtn
            });
        }
        let regNoCol = tempCols.find((element) => element.key === 'reg');

        if (regNoCol) {
            regNoCol.formatter = renderRegNo;
        }
        return tempCols;
    }, []);

    const checkButton = (ev, label) => {
        let scheduleID = null;
        if (props.contractStatusID == 1) scheduleID = ev.summerServiceScheduleID;
        if (props.contractStatusID == 5) scheduleID = ev.onhireScheduleID;
        if (props.contractStatusID == 7) scheduleID = ev.offhireScheduleID;
        // if (props.contractStatusID == 10) scheduleID = ev.factoryInspectionScheduleID;
        setOpenDialog({ [label]: true });
        setState((st) => ({
            ...st,
            selectedRowData: ev,
            scheduleID: scheduleID
        }));
        // pullApiData();
    };

    const closeTheDialog = (res) => {
        setOpenDialog({});
        // setState((st) => ({
        //     ...st,
        //     checkedVehicleList: []
        // }));

        setState((st) => {
            const newSt = { ...st };
            newSt.checkedVehicleList = [];
            if (res) newSt.isReload = new Date();
            return newSt;
        });
    };

    const urlState = `VehicleContract?StatusID=${props.contractStatusID}`;

    return (
        <div>
            <GijgoGrid
                columns={columns}
                getUrl={urlState}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                selectionType={
                    props.contractStatusID !== 1 && props.contractStatusID !== 5 && props.contractStatusID !== 7
                        ? //  && props.contractStatusID !== 10
                          'multiple'
                        : ''
                }
                selectionMethod={
                    props.contractStatusID !== 1 && props.contractStatusID !== 5 && props.contractStatusID !== 7
                        ? // && props.contractStatusID !== 10
                          'checkbox'
                        : ''
                }
                showCheckboxColFilterBtn={
                    props.contractStatusID !== 1 && props.contractStatusID !== 5 && props.contractStatusID !== 7
                        ? // && props.contractStatusID !== 10
                          mailButton
                        : null
                }
                isReload={state.isReload}
                // btnTitle="Add Contract Schedule"
                // onBtnClick={addBtnClicked}
            />
            {openDialog.complete ? (
                <DialogComp title={`Complete Schedule`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <CompleteScheduleModalForContract scheduleID={state.scheduleID} rowData={state.selectedRowData} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.setDeliveryDateModal ? (
                <DialogComp title={`Arrange Delivery`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetDeliveryDateModal rowData={state.selectedRowData} onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                    <SelectedVehicleTableComp selectedRecords={state.selectedRecords} />
                </DialogComp>
            ) : null}
            {openDialog.setCollectedModal ? (
                <DialogComp title={`Collected`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetCollectedModal rowData={state.selectedRowData} onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                    <SelectedVehicleTableComp selectedRecords={state.selectedRecords} />
                </DialogComp>
            ) : null}
            {openDialog.setOnsiteDateModal ? (
                <DialogComp title={`Set Vehicle Onsite`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetOnsiteDateModal rowData={state.selectedRowData} onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                    <SelectedVehicleTableComp selectedRecords={state.selectedRecords} />
                </DialogComp>
            ) : null}
            {openDialog.setPauseCompleted ? (
                <DialogComp title={`Contract Pause / Complete`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetPauseCompleted rowData={state.selectedRowData} onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                    <SelectedVehicleTableComp selectedRecords={state.selectedRecords} />
                </DialogComp>
            ) : null}
            {openDialog.setOffHireModal ? (
                <DialogComp title={`Set Vehicle Off Hire`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetOffHireModal rowData={state.selectedRowData} onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                    <SelectedVehicleTableComp selectedRecords={state.selectedRecords} />
                </DialogComp>
            ) : null}
            {openDialog.setVehiclePickedModal ? (
                <DialogComp title={`Vehicle Picked`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetVehiclePickedModal rowData={state.selectedRowData} onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                    <SelectedVehicleTableComp selectedRecords={state.selectedRecords} />
                </DialogComp>
            ) : null}
            {openDialog.setAwaitingFactoryInspection ? (
                <DialogComp title={`Set Vehicle Arrive Date`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetAwaitingFactoryInspection
                        rowData={state.selectedRowData}
                        onClose={closeTheDialog}
                        checkedVehicleList={state.checkedVehicleList}
                    />
                    <SelectedVehicleTableComp selectedRecords={state.selectedRecords} />
                </DialogComp>
            ) : null}
            {openDialog.setResumeContract ? (
                <DialogComp title={`Resume Contract`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetResume rowData={state.selectedRowData} onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                    <SelectedVehicleTableComp selectedRecords={state.selectedRecords} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default ContractStatusScreen;
