import React, { useEffect, useState, useContext } from 'react';
import { FormHelperText, InputLabel, Table, TableBody, TableContainer, TableHead, TableRow, Hidden, TableCell, Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { VehicleProvider as StateProvider, VehicleScreenContext } from './VehicleProvider';
import AddEditVehicle from './addEditVehicle';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import AddBreaddownModal from '../../Maintenance/BreakDown/AddBreakdown';
import AddDefectModal from '../../Maintenance/Defects/addEditDefectScreen';
import '../controllerStyle.scss';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import StickyNotes from './../Notes/stickyNotes';
import { CustomIconButton, SecondaryButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { getUpcomingEvents } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { DatePicker, CustomChip, SpeedDials } from '../../../../Core/Controls';
import moment from 'moment';
import { MandatoryPositiveInput } from '../../../../Core/Controls/Inputs/PositiveInput';
import { useToast } from '../../../../Core/Hooks/useToast';
import CustomizedTables from '../../../../Core/Controls/Table';
import { postMileageLog } from '../../../../Core/Services/InternalUser/ControllerService/mileageLogService';
import AddVor from '../VOR/AddEditVor';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import AddIcon from '@material-ui/icons/Add';
import BuildIcon from '@material-ui/icons/Build';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import KeyDates from './vehicleDetail';
import { DriverInfo } from './DriverInfo/index';
import FileUploaderButton from '../../../../Core/Controls/GenericFileUploader';
import DriverDailyChecks from './DriverDailyChecks/driverDailyChecks';
import formatters from '../../../../Core/Controls/Grid/Inputformatter';
import AddBatteryLevels from './BatteryLevels/VehicleBatteryLevels';
import CustomerDetails from '../DetailsModal/customerDetails';
import DepotDetails from '../DetailsModal/depotDetails';
import ServiceAgentDetails from '../DetailsModal/serviceAgent';
import endUserDetails from '../DetailsModal/endUserDetails';
import EndUserDetails from '../DetailsModal/endUserDetails';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveVehicleModal from './archiveVehicle';
import ContractStepper from '../../ContractScreens/ContractStatusTab/contractStepper';
import { Alert } from '@material-ui/lab';
import JobScreenModal from '../JOBS/JobScreenModal';
import AllStickyNotes from '../Notes/stickyAllNotes';

const gridStyle = {
    title: {
        color: '#f50057'
    },
    header: {
        borderBottom: '1px solid rgba(0,0,0,0.2)'
    },
    tbHead: {
        padding: '5px 0px 6px 8px'
    },
    tbContent: {
        margin: '8px 0px 14px 0px;'
    }
};
let checkhaveAccess = AppStorage.getAllowAcces();

const UpcomingEvents = ({ rows, changeIndex }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <div style={gridStyle.header}>
                    <div style={gridStyle.title}>
                        <Grid container style={{ alignItems: 'center', border: '1px solid #cccccc', borderBottom: 'none' }}>
                            <Grid xs={12} item style={{ alignItems: 'center' }}>
                                <Typography variant="h6" color="secondary" style={gridStyle.tbHead}>
                                    Upcoming Events
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <TableContainer style={{ minHeight: 100, height: 'auto', maxHeight: 300, border: '1px solid rgba(0,0,0,0.2)' }} className="custom-scroll">
                    <div>
                        <Table aria-label="simple table" size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Event&nbsp;Name</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows?.map((row) => {
                                    let notifyColor = '';
                                    let textCol = 'black';
                                    let todayDate = moment().format();
                                    let date1 = new Date(todayDate);
                                    let date2 = new Date(row.dateDue);
                                    let diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
                                    if (diffDays >= 14) {
                                        notifyColor = '#dff0d8';
                                    } else if (diffDays <= 0) {
                                        notifyColor = 'rgba(255, 8, 0,0.6)';
                                        textCol = 'white';
                                    } else if (diffDays > 0) {
                                        notifyColor = 'rgba(255,191,0,0.6)';
                                        textCol = 'white';
                                    }
                                    return (
                                        <TableRow key={row.scheduleName} style={{ backgroundColor: notifyColor, color: textCol }}>
                                            <TableCell style={{ color: 'inherit' }}>{row.scheduleName}</TableCell>
                                            <TableCell style={{ color: 'inherit' }}>{row.dateDue ? moment(row.dateDue).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                                            {/* <TableCell>{row.bookingStatus}</TableCell> */}
                                            <TableCell align="right">
                                                <CustomIconButton
                                                    icon={AccessTimeIcon}
                                                    color={textCol}
                                                    className="modal-btn-update btn_space"
                                                    toolTipTitle="Schedules"
                                                    onClick={() => {
                                                        changeIndex('Schedules');
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {rows?.length <= 0 && (
                                    <TableRow key={1}>
                                        <TableCell align="center" colSpan={3} style={{ color: 'inherit' }}>
                                            No upcoming events
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

const MileageLogSection = (props) => {
    const [state, setState] = useState({
        odometerReadingID: null,
        odometerReadingVehicleID: props.vehicleID,
        odometerReadingDate: '',
        odometerReading: ''
    });
    const [error, setError] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    const validate = () => {
        let errors = {};
        let isValid = true;
        const { odometerReadingDate, odometerReading } = state;
        if (!/[^\s]/.test(odometerReadingDate)) {
            errors.odometerReadingDate = 'Reading date is required';
            isValid = false;
        }
        if (+odometerReading < 0) {
            errors.odometerReading = 'Odometer reading can not be negative';
            isValid = false;
        }
        if (!/[^\s]/.test(odometerReading)) {
            errors.odometerReading = 'Odometer reading  is required';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const handleData = async () => {
        if (validate()) {
            let res = await postMileageLog({
                odometerReadingID: state.odometerReadingID,
                odometerReadingVehicleID: state.odometerReadingVehicleID,
                odometerReadingDate: state.odometerReadingDate,
                odometerReading: +state.odometerReading,
                odometerReadingCaptureSource: 'Manual Entry',
                odometerReadingActive: true
            });
            if (res.success) {
                showToastSuccess('Mileage Log added sucessfully');
                props.reloadData();
                props.reloadMileageData(props.VehicleID);
                setState((state) => ({
                    ...state,
                    odometerReadingDate: '',
                    odometerReading: ''
                }));
            } else {
                showToastError(res?.message || 'Something went wrong!');
                setState((state) => ({
                    ...state,
                    odometerReadingDate: '',
                    odometerReading: ''
                }));
            }
        }
    };

    const inputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    return (
        // <Grid xs={12} sm={3} md={3} lg={3} xl={3} item></Grid>
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                        <div style={gridStyle.header}>
                            <div style={gridStyle.title}>
                                <Grid container style={{ alignItems: 'center' }}>
                                    <Grid xs={12} sm={3} md={3} lg={3} xl={3} item style={{ alignItems: 'center' }}>
                                        <Typography variant="h6" color="secondary" style={gridStyle.tbHead}>
                                            Mileage Log
                                        </Typography>
                                    </Grid>

                                    <Grid xs={12} sm={9} md={9} lg={9} xl={9} container item justify="flex-end">
                                        <Grid item xs={9}>
                                            <Typography variant="caption" style={{ fontSize: '0.800rem', color: 'black', paddingLeft: '10px' }}>
                                                Latest Mileage {formatters.numberThousandsSeparator(props.otherFields.vehicleLatestMileage)}
                                                {props.otherFields.vehicleLastMileageDate != 'N/A' ? (
                                                    <span> on {props.otherFields.vehicleLastMileageDate}</span>
                                                ) : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item container xs={3} justify="flex-end" style={{ paddingRight: '10px' }}>
                                            <CustomChip icon={AssignmentIcon} label="History" onClick={() => props.changeIndex('Mileage')} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        {props.showEdit && (
                            <>
                                <Table aria-label="simple table" size="small">
                                    <TableBody>
                                        <Hidden only={['lg', 'xl', 'md', 'sm']}>
                                            <TableRow key={1}>
                                                <TableCell style={{ color: 'inherit', padding: '5px' }} width="100%">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <InputLabel required shrink>
                                                                Reading Date
                                                            </InputLabel>
                                                            <DatePicker
                                                                name="odometerReadingDate"
                                                                value={
                                                                    (state.odometerReadingDate && moment(state.odometerReadingDate).format('YYYY-MM-DD')) || ''
                                                                }
                                                                // disabled={props.btnFlag == 'delete' ? true : false}
                                                                onChange={inputChange}
                                                            />
                                                            <FormHelperText error>{error.odometerReadingDate}</FormHelperText>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MandatoryPositiveInput
                                                                inputLabel="Odometer Reading"
                                                                placeholder="Odometer Reading"
                                                                name="odometerReading"
                                                                value={state.odometerReading || ''}
                                                                onChange={inputChange}
                                                                errors={error.odometerReading}
                                                                disabled={props.btnFlag == 'delete' ? true : false}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <InputLabel>{'\u{2800}'}</InputLabel>
                                                            <SecondaryButton onClick={handleData}>Add</SecondaryButton>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        </Hidden>
                                        <Hidden only={['xs']}>
                                            <TableRow key={1}>
                                                <TableCell style={{ color: 'inherit', padding: '5px' }} width="30%">
                                                    <InputLabel required shrink>
                                                        Reading Date
                                                    </InputLabel>
                                                    <DatePicker
                                                        name="odometerReadingDate"
                                                        value={(state.odometerReadingDate && moment(state.odometerReadingDate).format('YYYY-MM-DD')) || ''}
                                                        onChange={inputChange}
                                                    />
                                                    <FormHelperText error>{error.odometerReadingDate}</FormHelperText>
                                                </TableCell>
                                                <TableCell style={{ color: 'inherit', padding: '5px' }} width="65%">
                                                    <MandatoryPositiveInput
                                                        inputLabel="Odometer Reading"
                                                        placeholder="Odometer Reading"
                                                        name="odometerReading"
                                                        value={state.odometerReading || ''}
                                                        onChange={inputChange}
                                                        errors={error.odometerReading}
                                                        disabled={props.btnFlag == 'delete' ? true : false}
                                                    />
                                                </TableCell>
                                                <TableCell align="right" width="5%" style={{ padding: '5px' }}>
                                                    <InputLabel>{'\u{2800}'}</InputLabel>
                                                    <SecondaryButton onClick={handleData} disabled={!checkhaveAccess?.CanUpdateVehicleMileages}>
                                                        Add
                                                    </SecondaryButton>
                                                </TableCell>
                                            </TableRow>
                                        </Hidden>
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export const VehicleDashBoard = (props) => {
    const { otherFields, pullMileageData, state, dateFields, jobFields, getVehicleDataById } = useContext(VehicleScreenContext);
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const handleClose = () => {
        setModalState((st) => ({
            ...st,
            customerModal: false,
            depotModal: false,
            serviceAgentModal: false,
            endUserModal: false
        }));
    };

    const [modalState, setModalState] = useState({
        customerModal: false,
        depotModal: false,
        serviceAgentModal: false,
        endUserModal: false
    });

    const renderBold = (toRender, modalName) => {
        return toRender ? (
            <div onClick={() => handleRowClick(modalName)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {toRender}
            </div>
        ) : (
            ''
        );
    };

    const handleRowClick = (toTurnOn) => {
        setModalState((st) => ({
            ...st,
            [toTurnOn]: true
        }));
    };
    // ownershipType;

    const tableData = [
        {
            name1: 'Customer',
            value1: renderBold(state.customerName, 'customerModal'),
            name2: 'Depot',
            value2: renderBold(state.depotName, 'depotModal'),
            name3: 'Primary Service Area',
            value3: renderBold(otherFields.primaryAgent, 'serviceAgentModal')
        },
        {
            name1: 'Engineer',
            value1: `${otherFields.engineer || ''}`,
            name2: 'Ownership Type',
            value2: `${otherFields.ownershipType || ''}`,
            name3: 'Make/Model',
            value3: `${state.makeDescription || 'N/A'} / ${state.modelID || 'N/A'}`
        },
        {
            // name1: 'Contract Number',
            // value1: `${otherFields.vehicleMaintenanceRef || ''}`,
            name1: 'Vehicle Type',
            value1: `${state.vehicleTypeDescription || ''}`,
            name2: 'Vehicle Category',
            value2: `${state.vehicleCategoryDescription || ''}`,
            name3: 'Colour',
            value3: `${otherFields.colour || ''}`
        },
        {
            name1: 'Serial Number',
            value1: `${otherFields.assetNumber || ''}`,
            name2: 'Fleet Number',
            value2: `${otherFields.fleetNumber || ''}`,
            name3: 'Chassis Number',
            value3: `${state.chassisNumber || ''}`
        },
        {
            name1: 'Engine Capacity',
            value1: `${state.capacity || ''}`,
            name2: 'CO2 Emission',
            value2: `${state.co2Emission || ''}`,
            name3: 'Vehicle Check Frequency',
            value3: `${otherFields.vehicleCheckFrequency || ''}`
        },
        {
            name1: 'Date Of Reg',
            value1: `${dateFields.dateOfReg1 || ''}`,
            name2: 'MOT Expiry',
            value2: `${dateFields.motExpiry1 || ''}`,
            name3: 'Inspection Schedule',
            value3: `${otherFields.schedule || ''}`
        },
        {
            // name2: 'End User',
            // value2: renderBold(state.endUserName, 'endUserModal'),
            name1: 'Fuel Type',
            value1: `${state.vehicleFuelType || ''}`,
            name2: 'Ministry Number',
            value2: `${otherFields.ministryNumber || ''}`,
            name3: 'Next Service',
            value3: `${
                dateFields.vehicleNextServiceDateDueDisplay && otherFields.vehicleNextServiceMileage
                    ? otherFields.service +
                      ' on ' +
                      formatters.YearFormatter(dateFields.vehicleNextServiceDateDueDisplay) +
                      '(' +
                      otherFields.vehicleNextServiceMileage +
                      ')'
                    : otherFields.service || ''
            }`
        },
        {
            name1: 'Chassis Warranty Years',
            value1: `${otherFields.vehicleChassisWarrantyYears || ''}`,
            name2: 'Body Warranty Years',
            value2: `${otherFields.vehicleBodyWarrantyYears || ''}`,
            name3: 'Tracker',
            value3: `${otherFields.vehicleTracker || ''}`
        },
        {
            name1: 'Speed Limiter',
            value1: `${otherFields.vehicleSpeedLimiter || ''}`,
            name2: 'Vaisala',
            value2: `${otherFields.vehicleVaisala || ''}`,
            name3: 'Distributor',
            value3: `${otherFields.vehicleDistributor || ''}`
        },
        {
            name1: 'Camera System',
            value1: `${otherFields.vehicleCameraSystem || ''}`,
            name2: 'Sheeting System',
            value2: `${otherFields.vehicleSheetingSystem || ''}`,
            name3: 'Control System',
            value3: `${otherFields.vehicleControlSystem || ''}`
        },
        {
            name1: 'On Board Weighing System',
            value1: `${otherFields.vehicleOnboardWeighingSystem || ''}`,
            name2: 'Gross Weight',
            value2: `${otherFields.vehicleGrossWeight || ''}`,
            name3: 'Tyre Size',
            value3: `${otherFields.vehicleTyreSizeAxle1 || ''}`
        }
    ];

    const [data, setData] = useState({
        show: false,
        VehicleID: null,
        showBreakDownPopup: false,
        showDetails: true,
        showVORPopup: false,
        upcomingEventsList: [],
        open: false,
        showCheck: false,

        showArchivePopup: false
    });

    const pullData = async () => {
        if (props.VehicleID) {
            setData((st) => ({
                ...st,
                VehicleID: props.VehicleID
            }));
            let res = await getUpcomingEvents(props.VehicleID);
            if (res.success) {
                setData((st) => ({ ...st, upcomingEventsList: res.data.vehicleUncompletedSchedulesList }));
            } else {
                setData((st) => ({ ...st, upcomingEventsList: [] }));
            }
        }
    };

    useEffect(() => {
        pullData();
    }, [data.show, data.showBreakDownPopup, data.showDefectPopup, props.VehicleID]);

    const prepareData = () => ({
        show: false,
        showDetails: false,
        showBreakDownPopup: false,
        showDefectPopup: false,
        showArchivePopup: false
    });

    const handleAdd = (id, name) => {
        setData((st) => ({ ...prepareData(), [name]: true, VehicleID: id }));
    };

    const handleAddPopUp = (id, name) => {
        setData((st) => ({ ...st, [name]: true, VehicleID: id }));
    };

    const closeAddPopUp = (flag, name) => {
        setData((st) => ({ ...st, showVORPopup: false, showJobPopup: false }));
        flag && getVehicleDataById();
        flag && props.pullDetails();
    };

    const closeModal = (res) => {
        setData((st) => ({
            ...st,
            show: false,
            showBreakDownPopup: false,
            showDefectPopup: false,
            showDetails: true,
            showCheck: false,
            showArchivePopup: false
        }));
        res && props.pullDetails();
        res && getVehicleDataById();
    };
    const showBtn = data.showBreakDownPopup || data.showDefectPopup || data.show;
    let { isReadOnlyUser } = useLoginInfo();

    const Header = () => {
        return !isReadOnlyUser && !showBtn ? (
            <Grid container style={{ alignItems: 'center', padding: '0px 6px' }}>
                <Grid xs={9} sm={6} md={3} lg={3} xl={3} item>
                    <Typography variant="h6" color="secondary">
                        Vehicle Profile &nbsp;
                        <span style={{ position: 'relative', top: '-5px', left: '-5px' }}>
                            <FileUploaderButton recordId={props.VehicleID} fileUploaderConfigName="VehicleScreen" fileCount={state.fileCount} isDash={true} />
                        </span>
                    </Typography>
                </Grid>

                <>
                    <Hidden only={['xs', 'sm']}>
                        <Grid xs={12} sm={9} md={9} lg={9} xl={9} item>
                            <div className="dash-controller-btn" style={{ float: 'right' }}>
                                &nbsp;
                                {jobFields?.vehicleActive && (
                                    <>
                                        {checkhaveAccess?.CanUpdateVehicles && (
                                            <CustomChip className="btn-m" icon={EditIcon} label="Edit" onClick={() => handleAdd(data.VehicleID, 'show')} />
                                        )}
                                        {jobFields.vorVehicleID ? null : (
                                            <>
                                                {checkhaveAccess?.CanAddVOR ? (
                                                    <CustomChip
                                                        className="btn-m"
                                                        icon={AddIcon}
                                                        label="Add VOR"
                                                        onClick={() => handleAddPopUp(data.VehicleID, 'showVORPopup')}
                                                    />
                                                ) : null}
                                            </>
                                        )}

                                        <>
                                            {/* {checkhaveAccess?.CanUpdateJobs ? ( */}
                                            <CustomChip
                                                className="btn-m"
                                                icon={AddIcon}
                                                label="Add Job"
                                                onClick={() => handleAddPopUp(data.VehicleID, 'showJobPopup')}
                                            />
                                            {/* ) : null} */}
                                        </>

                                        {checkhaveAccess?.CanAddDailyChecks && (
                                            <CustomChip
                                                className="btn-m"
                                                icon={AddIcon}
                                                label="Add Check"
                                                onClick={() => handleAdd(data.VehicleID, 'showCheck')}
                                            />
                                        )}
                                        {checkhaveAccess?.CanAddBreakdowns && (
                                            <CustomChip
                                                className="btn-m"
                                                icon={BuildIcon}
                                                label="Add Breakdown"
                                                onClick={() => handleAdd(data.VehicleID, 'showBreakDownPopup')}
                                            />
                                        )}
                                        {checkhaveAccess?.CanAddDefects && (
                                            <CustomChip
                                                className="btn-m"
                                                icon={LocalShippingIcon}
                                                label="Add Defect"
                                                onClick={() => handleAdd(data.VehicleID, 'showDefectPopup')}
                                            />
                                        )}
                                    </>
                                )}
                                {checkhaveAccess?.CanArchiveVehicle && (
                                    <CustomChip
                                        className="btn-m"
                                        icon={DeleteIcon}
                                        label={jobFields?.vehicleActive ? 'Archive' : 'Unarchive'}
                                        onClick={() => handleAddPopUp(data.VehicleID, 'showArchivePopup')}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Grid xs={3} sm={6} item>
                            <FormatListBulletedIcon onClick={handleChange} style={{ cursor: 'pointer', float: 'right', padding: 10 }} />
                        </Grid>
                        <Collapse in={checked}>
                            <Grid xs={12} item>
                                <div className="dash-controller-btn" style={{ float: 'right' }}>
                                    &nbsp;
                                    {jobFields?.vehicleActive && (
                                        <>
                                            <CustomChip className="btn-m" icon={EditIcon} label="Edit" onClick={() => handleAdd(data.VehicleID, 'show')} />
                                            {jobFields.vorVehicleID ? null : (
                                                <>
                                                    {checkhaveAccess?.CanAddVOR ? (
                                                        <CustomChip
                                                            className="btn-m"
                                                            icon={AddIcon}
                                                            label="Add VOR"
                                                            onClick={() => handleAddPopUp(data.VehicleID, 'showVORPopup')}
                                                        />
                                                    ) : null}
                                                </>
                                            )}

                                            <>
                                                {checkhaveAccess?.CanUpdateJobs ? (
                                                    <CustomChip
                                                        className="btn-m"
                                                        icon={AddIcon}
                                                        label="Add Job"
                                                        onClick={() => handleAddPopUp(data.VehicleID, 'showJobPopup')}
                                                    />
                                                ) : null}
                                            </>
                                            {checkhaveAccess?.CanAddDailyChecks && (
                                                <CustomChip
                                                    className="btn-m"
                                                    icon={AddIcon}
                                                    label="Add Check"
                                                    onClick={() => handleAdd(data.VehicleID, 'showCheck')}
                                                />
                                            )}
                                            {checkhaveAccess?.CanAddBreakdowns && (
                                                <CustomChip
                                                    className="btn-m"
                                                    icon={BuildIcon}
                                                    label="Add Breakdown"
                                                    onClick={() => handleAdd(data.VehicleID, 'showBreakDownPopup')}
                                                />
                                            )}
                                            {checkhaveAccess?.CanAddDefects && (
                                                <CustomChip
                                                    className="btn-m"
                                                    icon={LocalShippingIcon}
                                                    label="Add Defect"
                                                    onClick={() => handleAdd(data.VehicleID, 'showDefectPopup')}
                                                />
                                            )}
                                        </>
                                    )}
                                    {checkhaveAccess?.CanArchiveVehicle && (
                                        <CustomChip
                                            className="btn-m"
                                            icon={DeleteIcon}
                                            label={jobFields?.vehicleActive ? 'Archive' : 'Unarchive'}
                                            onClick={() => handleAddPopUp(data.VehicleID, 'showArchivePopup')}
                                        />
                                    )}
                                </div>
                            </Grid>
                        </Collapse>
                    </Hidden>
                </>

                {/* <Grid xs={1} item>
                    <div style={{ marginBottom: 45 }}>
                        <SpeedDials actions={actions} direction={'down'} />
                    </div>
                </Grid> */}
            </Grid>
        ) : null;
    };
    const KeyDateHeader = () => {
        return (
            <div style={gridStyle.header}>
                <div style={gridStyle.title}>
                    <Grid container style={{ alignItems: 'center' }}>
                        <Grid xs={12} item style={{ alignItems: 'center' }}>
                            <Typography variant="h6" color="secondary" style={gridStyle.tbHead}>
                                Key Dates
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };

    return (
        <>
            {modalState.customerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {modalState.depotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.depotsID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {modalState.serviceAgentModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.vehiclePrimaryServiceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {modalState.endUserModal ? (
                <DialogComp open={true} onClose={handleClose} title={'End User Details'}>
                    <EndUserDetails endUserID={state.vehicleEndUserID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}

            <div className="controller-screen">
                {!(data.show || data.showBreakDownPopup || data.showDefectPopup || data.showCheck) && (
                    <Grid container spacing={2}>
                        {/* <Grid container item xs={12}> */}
                        {props.displayVehicleProgress ? (
                            <Grid item xs={12}>
                                <ContractStepper vehicleID={props.VehicleID} vehicleContractID={state.vehicleContractID} />
                            </Grid>
                        ) : null}
                        <Grid item sm={12} lg={7}>
                            <Grid container spacing={2} className="screen">
                                {!showBtn && (
                                    <Grid item xs={12}>
                                        {/* <StickyNotes frompage="Customers" customerID={props?.customerID || state?.customerID} bgColor="#ffcf89" />
                                        <StickyNotes frompage="Depots" depotID={props?.depotID || state?.depotID} bgColor="#ffcf89" />
                                        <StickyNotes frompage="Vehicle" NoteVehicleID={props?.VehicleID} variant="filled" /> */}

                                        <AllStickyNotes
                                            frompage="Vehicle"
                                            // jobID={props?.scheduleJobID}
                                            customerID={props?.customerID || state?.customerID}
                                            depotID={props?.depotID || state?.depotID}
                                            NoteVehicleID={props?.VehicleID}
                                            // variant="filled"
                                        />

                                        {/* {otherFields.contractTypeID == 2 && (
                                            <div style={{ paddingTop: '5px' }}>
                                                <Alert severity="info">This Vehicle is on Contract Maintenance</Alert>
                                            </div>
                                        )} */}
                                        {props.customerIsOnStop && (
                                            <div style={{ paddingTop: '5px' }}>
                                                <Alert variant="filled" severity="error">
                                                    The customer - {props?.customerCompanyName} is on stop
                                                </Alert>
                                            </div>
                                        )}
                                        {props.customerCreditAccountMessage && (
                                            <div style={{ paddingTop: '5px' }}>
                                                <Alert variant="filled" severity="error">
                                                    {props.customerCreditAccountMessage}
                                                </Alert>
                                            </div>
                                        )}
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={12}>
                                    {data.showDetails && <CustomizedTables tableHeader={Header()} tableData={tableData} />}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <MileageLogSection
                                        showEdit={jobFields?.vehicleActive}
                                        vehicleID={props.VehicleID}
                                        reloadData={pullData}
                                        otherFields={otherFields}
                                        reloadMileageData={pullMileageData}
                                        VehicleID={props.VehicleID}
                                        changeIndex={props.changeIndex}
                                    />
                                </Grid>
                                {jobFields?.vehicleActive && (
                                    <>
                                        {otherFields.displayBatteryLevel == 'Y' && (
                                            <Grid item xs={12} sm={12}>
                                                <AddBatteryLevels
                                                    vehicleID={props.VehicleID}
                                                    reloadData={pullMileageData}
                                                    otherFields={otherFields}
                                                    VehicleID={props.VehicleID}
                                                    changeIndex={props.changeIndex}
                                                />
                                            </Grid>
                                        )}
                                        {checkhaveAccess?.CanViewDrivers && (
                                            <Grid item xs={12} sm={12}>
                                                <DriverInfo
                                                    vehicleID={props.VehicleID}
                                                    driver={state.driver}
                                                    driverID={state.vehicleDriverID}
                                                    vehicleDisc={props.vehicleDisc}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item sm={12} lg={5}>
                            {!showBtn && (
                                <Grid item xs={12} sm={12} md={12} lg={12} spacing={2}>
                                    <Grid item container spacing={2} style={{ marginTop: '1px' }}>
                                        <Grid item xs={12} sm={12}>
                                            <KeyDates
                                                regNo={state.regNumber}
                                                VehicleID={props.VehicleID}
                                                onFormSubmit={closeModal}
                                                tableHeader={KeyDateHeader()}
                                                jobFields={jobFields}
                                                dateFields={dateFields}
                                                changeIndex={props.changeIndex}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            {!showBtn && <UpcomingEvents rows={data.upcomingEventsList} changeIndex={props.changeIndex} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}

                {/* {!(data.show || data.showBreakDownPopup || data.showDefectPopup || data.showCheck) && (
                    <Grid container spacing={2} className="screen">
                        {!showBtn && (
                            <div style={{ width: '100%', padding: '4px 4px' }}>
                                <StickyNotes frompage="Vehicle" NoteVehicleID={props.VehicleID} />
                            </div>
                        )}
                        <Grid container item lg={7} md={12} sm={12} xs={12} spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {data.showDetails && <CustomizedTables tableHeader={Header()} tableData={tableData} />}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <MileageLogSection
                                    showEdit={jobFields?.vehicleActive}
                                    vehicleID={props.VehicleID}
                                    reloadData={pullData}
                                    otherFields={otherFields}
                                    reloadMileageData={pullMileageData}
                                    VehicleID={props.VehicleID}
                                    changeIndex={props.changeIndex}
                                />
                            </Grid>
                            {jobFields?.vehicleActive && (
                                <>
                                    {otherFields.displayBatteryLevel == 'Y' && (
                                        <Grid item xs={12} sm={12}>
                                            <AddBatteryLevels
                                                vehicleID={props.VehicleID}
                                                reloadData={pullMileageData}
                                                otherFields={otherFields}
                                                VehicleID={props.VehicleID}
                                                changeIndex={props.changeIndex}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12}>
                                        <DriverInfo
                                            vehicleID={props.VehicleID}
                                            driver={state.driver}
                                            driverID={state.vehicleDriverID}
                                            vehicleDisc={props.vehicleDisc}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        {!showBtn && (
                            <Grid item xs={12} sm={12} md={12} lg={5} spacing={2}>
                                <Grid item container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <KeyDates regNo={state.regNumber} VehicleID={props.VehicleID} onFormSubmit={closeModal} tableHeader={KeyDateHeader()} jobFields={jobFields} dateFields={dateFields} changeIndex={props.changeIndex} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {!showBtn && <UpcomingEvents rows={data.upcomingEventsList} changeIndex={props.changeIndex} />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )} */}

                <div className="mt_10">
                    {data.show ? <AddEditVehicle VehicleID={data.VehicleID} onFormSubmit={closeModal} /> : null}
                    {data.showBreakDownPopup ? <AddBreaddownModal VehicleID={data.VehicleID} onClose={() => closeModal()} /> : null}
                    {data.showDefectPopup ? <AddDefectModal VehicleID={data.VehicleID} onClose={() => closeModal()} /> : null}
                    {data.showCheck ? <DriverDailyChecks VehicleID={data.VehicleID} onClose={() => closeModal()} /> : null}
                    {data.showArchivePopup ? (
                        <ArchiveVehicleModal VehicleActive={jobFields.vehicleActive} VehicleID={data.VehicleID} onClose={closeModal} />
                    ) : null}
                    {data.showVORPopup ? (
                        <DialogComp
                            title={`${jobFields.vorVehicleID ? 'Update' : 'Add'} VOR - ${state.regNumber}`}
                            onClose={() => closeAddPopUp(false)}
                            maxWidth="lg"
                            overflow="auto"
                        >
                            <AddVor VehicleID={data.VehicleID} update={jobFields.vorVehicleID} vorVehicleID={jobFields.vorVehicleID} onClose={closeAddPopUp} />
                        </DialogComp>
                    ) : null}
                    {data.showJobPopup ? (
                        <DialogComp onClose={() => closeAddPopUp(false)} title="Add Job" maxWidth="md">
                            <JobScreenModal
                                VehicleID={data.VehicleID}
                                serviceAgentID={state.vehiclePrimaryServiceAgentID}
                                depotID={state.depotsID}
                                customerID={state.customerID}
                                technicianUserID={state.vehicleTechnicianUserID}
                                VORId={props.vorID}
                                onClose={closeAddPopUp}
                            />
                        </DialogComp>
                    ) : null}
                </div>
            </div>
        </>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <VehicleDashBoard {...props} />
        </StateProvider>
    );
};
export default Screen;
