import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { BreadCrumbs, EditButton, formatters, SecondaryButton } from '../../../../../Core/Controls';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
// import ContractEditModal from './contractAddEditModal';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import ContractHistoryDetails from './contractHistoryDetails';
const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 80,
        addFileUploaderIcon: true
    },
    { key: 'startDate', name: 'Start Date', formatter: formatters.Date_DDMMYYYY, width: '10%' },
    { key: 'endDate', name: 'End Date', formatter: formatters.Date_DDMMYYYY, width: '10%' },
    { key: 'winterStartDate', name: 'Winter Start Date', formatter: formatters.Date_DDMMYYYY, width: '10%' },
    { key: 'contractTerm', name: 'Contract Term', width: '11%' },
    { key: 'regNo', name: 'Reg No', width: '11%' },
    { key: 'ref', name: 'Ref', width: '12%' },
    { key: 'customer', name: 'Customer', width: '15%' },
    { key: 'agent', name: 'Service Area', width: '15%' },
    { key: 'depot', name: 'Depot', width: '15%' },
    { key: 'engineer', name: 'Engineer', width: '15%' }
];

const ContractHistoryList = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const history = useHistory();
    const [state, setState] = useState({ showModal: false, historyId: null });

    const editActionBtn = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleModal(row)} toolTipTitle="Edit Address" />
            </div>
        );
    };
    console.log(state);
    const handleModal = (res) => {
        console.log('res', res);
        setState((st) => ({ ...st, showModal: true, historyId: res.contractHistoryID }));
    };

    const handleClose = (res) => {
        setState((st) => ({ ...st, showModal: false, historyId: null }));
        // res && pullDepotsAndUpdateState();
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = editActionBtn;
        }
        return tempCols;
    }, []);

    let getUrl = `VehicleContrcatHistory?VehicleID=${props.VehicleID}`;

    useEffect(() => {
        if (props.contractHistoryID && props.contractHistoryID != 'undefined' && props.contractHistoryID != 'null') {
            setState((st) => ({ ...st, showModal: true, historyId: props.contractHistoryID }));
        }
    }, [props.contractHistoryID]);

    return (
        <div className="screen">
            <Grid container></Grid>
            {state.showModal ? (
                <>
                    <ContractHistoryDetails id={state.historyId} />
                    <SecondaryButton className="submit_btn" onClick={() => handleClose(false)}>
                        Cancel
                    </SecondaryButton>
                </>
            ) : (
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                />
            )}
        </div>
    );
};
export default ContractHistoryList;
