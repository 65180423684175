import React, { useEffect, useState, useMemo } from 'react';
import { EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { getPartStockAdjustments_GetNotes } from '../../../../Core/Services/Admin/partService';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import AddUpdatePart from '../PartAddUpdate';
import ReactDOM from 'react-dom';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Parts', path: '/parts' },
    { name: 'Parts Stock Adjustment History', path: '/', active: true }
];

const baseColumns = [
    { key: 'partNumber', name: 'Part Number', width: '8%' },
    { key: 'description', name: 'Description', width: '25%' },
    { key: 'date', name: 'Requested On', width: '10%' },
    { key: 'currentMinimumStockLevel', name: 'Current Minimum Stock', width: '15%', align: 'right' },
    { key: 'newMinimumStockLevel', name: 'New Minimum Stock', width: '13%', align: 'right' },
    { key: 'currentTargetStockLevel', name: 'Current Target Stock', width: '13%', align: 'right' },
    { key: 'newTargetStockLevel', name: 'New Target Stock', width: '13%', align: 'right' },
    { key: 'userName', name: 'Engineer', headerRenderer: formatters.ColumnHeaderAlignleft, width: '9%' }
];

const PartsAdjustmentHistory = (props) => {
    const isInternal = useLoginInfo().userRoles.includes('internal');
    const isServiceArea = useLoginInfo().userRoles.includes('servicearea');

    const [state, setState] = useState({
        show: false,
        orderHistory: [],
        isReload: null
    });

    const PartsStockAdjustmentHistory = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(() => {
        PartsStockAdjustmentHistory();
    }, []);

    const dataFormatter = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                title="Edit part"
                onClick={() => {
                    setState((st) => ({
                        ...st,
                        open: true,
                        title: `Part Details - ${row.partNumber}`,
                        partID: row.partID
                    }));
                }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.partNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'partNumber');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }

        if (isServiceArea) {
            tempCols.splice(7, 0, { key: 'warehouse', name: 'Warehouse', width: 110 });
        }
        if (isInternal) {
            tempCols.splice(7, 0, { key: 'warehouse', name: 'Warehouse', width: 110 });
            tempCols.splice(8, 0, { key: 'sa', name: 'Service Area', width: 110 });
        }

        return tempCols;
    }, []);
    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: res ? true : false
        }));
        res && PartsStockAdjustmentHistory();
    };
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={'AdminParts/PartStockAdjustments_List'}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp open={state.open} onClose={() => handleClose(false)} title={state.title} maxWidth="md" fullWidth fullScreen overflow="auto">
                    <AddUpdatePart partID={state.partID} onClose={handleClose} screenID={'A'} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default PartsAdjustmentHistory;
