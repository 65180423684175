import React, { useEffect, useState, useMemo } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import { AddButton, EditButton, DataGrid, BreadCrumbs, formatters } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { getPartRequests_GetNotes } from '../../../../Core/Services/Admin/partService';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import AddUpdatePart from '../PartAddUpdate';
import ReactDOM from 'react-dom';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { useHistory } from 'react-router-dom';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Parts', path: '/parts' },
    { name: 'Parts Request Order History', path: '/', active: true }
];

const baseColumns = [
    { key: 'partNumber', name: 'Part Number', width: '8%' },
    { key: 'description', name: 'Description' },
    { key: 'reg', name: 'Reg number', width: '8%' },
    { key: 'jr', name: 'Job Ref', width: '10%' },
    { key: 'date', name: 'Requested On', width: '8%' },
    { key: 'qty', name: 'Quantity', width: '8%', align: 'right' },
    { key: 'userName', name: 'Engineer', headerRenderer: formatters.ColumnHeaderAlignleft, width: '9%' },
    {
        key: 'type',
        name: 'Request Type',
        width: '10%'
    },
    { key: 'status', name: 'Status', width: '10%' }
];

const PartsOrderHistory = (props) => {
    const history = useHistory();

    const isInternal = useLoginInfo().userRoles.includes('internal');
    const [state, setState] = useState({
        open: false,
        orderHistory: [],
        isReload: null
    });

    const PartsOrderHistory = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(() => {
        PartsOrderHistory();
    }, []);

    const handleJobRefEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vehicleID}`,
            search: ''
            // state: { position: 'jobs', vorVehicleID: val.vorVehicleID }
        });
    };
    const renderJobRefEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleJobRefEditClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.jr}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: ''
            // state: { fileCount: val.fileCount }
        });
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.reg}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const dataFormatter = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                title="Edit part"
                onClick={() => {
                    setState((st) => ({
                        ...st,
                        open: true,
                        title: `Part Details - ${row.partNumber}`,
                        partID: row.partID,
                        isAdd: false
                    }));
                }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.partNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'partNumber');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        let jobRefCol = tempCols.find((element) => element.key === 'jr');
        if (jobRefCol) {
            jobRefCol.formatter = renderJobRefEdit;
        }
        let regNumberCol = tempCols.find((element) => element.key === 'reg');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (isInternal) {
            tempCols.splice(6, 0, { key: 'sa', name: 'Service Area', width: 110 });
        }
        return tempCols;
    }, []);

    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: res ? true : false
        }));
        res && PartsOrderHistory();
    };
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={'AdminParts/PartRequests_List'}
                isReload={state.isReload}
            />
            {state.open ? (
                <DialogComp open={state.open} onClose={() => handleClose(false)} title={state.title} maxWidth="md" fullWidth fullScreen overflow="auto">
                    <AddUpdatePart partID={state.partID} onClose={handleClose} screenID={'O'} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default PartsOrderHistory;
