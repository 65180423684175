import { Grid } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import { BreadCrumbs, formatters } from '../../../../../Core/Controls';
import reactDom from 'react-dom';
import { PurchaseModal } from './purchaseModal';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';

const base_columns = [
    { key: 'pd', name: 'Part Description', width: '250' },
    { key: 'pn', name: 'Part Number', width: '110' },
    { key: 'qty', name: 'Qty', width: '50' },
    { key: 'selling', name: 'Retail Price', width: '90', formatter: formatters.NumberWithPound },
    { key: 'cost', name: 'Cost Price', width: '90', formatter: formatters.NumberWithPound },
    { key: 'supp', name: 'Purchased From', width: '130' },
    { key: 'purchased', name: 'Purchased', width: '90', formatter: formatters.Date_DDMMYYYY },
    { key: 'pt', name: 'Payment', width: '110' },
    { key: 'wip', name: 'Invoice Ref', width: '110' },
    { key: 'jr', name: 'Job Reference', width: '150' },
    { key: 'reg', name: 'Reg No', width: '80' },
    { key: 'cust', name: 'Customer', width: '200' },
    { key: 'sa', name: 'Service Area', width: '100' },
    { key: 'eng', name: 'Engineer', width: '110' }
];

let getCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Jobs', active: true },
    { name: 'Purchased Part', active: true }
];

const PurchasePartsGrid = () => {
    const [state, setState] = useState({
        getUrl: 'VehicleJob/GetPurchasedParts',
        closePart: false,
        id: null
    });
    const history = useHistory();
    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vid}`,
            // search: isEngineer ? '?tab_testing_tabs=1' : '',
            state: 'jobs'
        });
    };
    const renderJobReference = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.jr}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                onClick={() =>
                    setState(() => ({
                        closePart: true,
                        id: row.id
                    }))
                }
                //    onClick={() => {
                //     //    if (props.screenCode == 'OJ' || props.screenCode == 'FR' || props.screenCode == 'CA') {
                //         //    handleEditClick(row);
                //     //    } else {
                //     //     //    handleEditRegClick(row);
                //     //    }
                //    }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.pd}
            </div>
        );
        reactDom.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'pd');
        let jobReferenceCol = newColumn.find((element) => element.key === 'jr');

        if (actionCol) {
            actionCol.formatter = renderRegNumberEdit;
        }
        if (jobReferenceCol) {
            jobReferenceCol.formatter = renderJobReference;
        }
        // if (props.screenCode == 'EP') {
        //     newColumn.splice(3, 0, {
        //         key: 'jobEstimatedCost',
        //         name: 'Estimate',
        //         formatter: formatters.ColumnTextAlignRight,
        //         headerRenderer: formatters.ColumnHeaderAlignRight
        //     });
        //     newColumn.splice(7, 2);
        // }
        return newColumn;
    }, [state.isReload]);

    return (
        <div style={{ padding: 10 }}>
            <Grid container xs={12}>
                <BreadCrumbs crumbs={getCrumbs} />{' '}
                <GijgoGrid
                    columns={columns}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    getUrl={state.getUrl}
                />
            </Grid>

            {state.closePart ? (
                <DialogComp
                    title={'Purchase Part'}
                    onClose={() =>
                        setState(() => ({
                            closePart: false,
                            id: null
                        }))
                    }
                    maxWidth="lg"
                >
                    <PurchaseModal id={state.id} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default PurchasePartsGrid;
